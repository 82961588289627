import { useEffect, useState } from "react";
import "./SubtitleAnimation.css";

export default function SubtitleAnimation (props)
{
    const [amountLines, setAmountLines] = useState(1);
    const [heightAnchor, setHeightAnchor] = useState(30);

    useEffect(() => {
        let heightAnchor = 30;
        let amountLines = props.amountLines || 1;

        if (amountLines > 1)
        { heightAnchor = (amountLines * 22); }

        setAmountLines(amountLines);
        setHeightAnchor(heightAnchor);
    },[props])

    return (
        <div className="subtitleAnimation anchor" style={{"height": `${heightAnchor}px`, "width": `${props?.widthSubtitleAnimation}`}}>
            <div className={`subtitleAnimation container ${props?.color} lines${amountLines}`}>
                <div className="contentSubtitleAnimation" style={{"-webkit-line-clamp": `${amountLines}`, "line-clamp": `${amountLines}`}}>
                    {props.content}
                </div>
            </div>
        </div>
    )
}