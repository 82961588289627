import React, { Component } from "react";
import "./Development.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { APPLICATION_NAME } from "../../core/constants";
import { setModalMessage } from "../../store/actions/general";

import Header from "../../components/CRM/Header";
import Box from "../../components/general/Box";
import SubtitleAnimation from "../../components/general/SubtitleAnimation";

const initialState = {
    variable: "",
}

// const whatsAppController = new WhatsAppController();
class Development extends Component
{
    state = {...initialState}

    componentDidMount = async () => 
    {
        document.title = `${APPLICATION_NAME} - Área de Desenvolvimento`;
    }

    componentWillUnmount = async () =>  { }
    
    componentDidUpdate = async (prevProps) =>
    {
        // console.log("SERVICE QUEUE prevState ", prevState);
        // console.log("SERVICE QUEUE this.state ", this.state.allSpecialists);
    }

    message = (type, message) =>
    {
        this.props.setModalMessage({show: true, type, message});
    }
    
    render ()
    {
        return (
            <div className="development">
                <Header title="Área de Desenvolvimento" classaditional="headerNavigationDevelopment"/>
                <div className="scrollDevelopment mb-4">
                    <div className="warningDevelopment d-flex d-md-none">
                        <span>Opsssss, alguns recursos foram desativados para dispositivos móveis.</span>
                    </div>
                    <div className="container headerDevelopment">
                        <Box>
                            <h4>Área de Desenvolvimento</h4>
                        </Box>
                        <Box>
                            <SubtitleAnimation 
                                content="Caso tenha mais serviços deste mesmo cliente feche aqui e cadastre os outros." 
                                color="success"
                                amountLines={1}
                            />
                            <br /><br /><br />
                            <h6>Avaliable System Colors</h6>
                            <div className="row lineColorDevelopment">
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-error)"}}>error</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-success)"}}>success</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-warning)"}}>warning</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-information)"}}>information</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-focus)"}}>focus</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-inactive)"}}>inactive</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-purple)"}}>purple</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-orange)"}}>orange</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-limon)"}}>limon</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-strawberry)"}}>strawberry</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-banana)"}}>banana</div>
                                <div className="colorDevelopment" style={{"backgroundColor": "var(--color-watermelon)"}}>watermelon</div>
                            </div>
                        </Box>
                        <div className="warningDevelopment b-warning">
                            <span>Faça os testes em outros navegadores também!</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    return {}
}

function mapDispatchToProps (dispatch)
{
    return { 
        setModalMessage (data)
        {
            //action creator -> action
            const action = setModalMessage(data);
            dispatch(action);
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Development));