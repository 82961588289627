import API from "../api";
import { apiCallbackTreatment } from "../auxiliary/generalFunctions";

const api = new API();

export default class BusinessAnalysisController 
{
    async create(data) 
    {
        const response = await api.businessAnalysis().post(data);
        return apiCallbackTreatment(response);
    }

    async deleteById(id)
    {
        const response = await api.businessAnalysis().remove(id);
        return apiCallbackTreatment(response);
    }

    async update(id, data) 
    {
        const response = await api.businessAnalysis().update(id, data);
        return apiCallbackTreatment(response);
    }

    async saveInstallments(data)
    {
        const response = await api.businessAnalysis().saveInstallments(data);
        return apiCallbackTreatment(response);
    }

    async sendDiscountToApproval(data)
    {
        const response = await api.businessAnalysis().sendDiscountToApproval(data);
        return apiCallbackTreatment(response);
    }

    async updateStatusDiscount(data) 
    {
        const response = await api.businessAnalysis().updateStatusDiscount(data);
        return apiCallbackTreatment(response);
    }

    async deleteDiscount(data) 
    {
        const response = await api.businessAnalysis().deleteDiscount(data);
        return apiCallbackTreatment(response);
    }

    async getDiscountByIdBusinessAnalysis(idAnalysis)
    {
        const response = await api.businessAnalysis().getDiscountByIdBusinessAnalysis(idAnalysis);
        return apiCallbackTreatment(response);
    }
}