import React, { useState } from "react";
import Button from "./Button";
import "./FileUpload.css";

const FileUpload = ({ onFileUpload }) => {
    const [file, setFile] = useState("");
    const [disableButton, setdDisableButton] = useState(false);
    const [filePath, setFilePath] = useState("");
    // const [fileType, setFileType] = useState("");
    const [isDragOver, setIsDragOver] = useState(false);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile.name);
            setFilePath(selectedFile);
            // setFileType(selectedFile.type);
        }
    };

    const triggerFileInput = () => {
        document.getElementById("fileInput").click();
    };

    const handleUploadClick = () => {
        if (onFileUpload && filePath) {
            onFileUpload(filePath);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragOver(false);
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile.name);
            setFilePath(droppedFile);
            // setFileType(droppedFile.type);
        }
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    return (
        <div className="containerFileUpload">
            <div className="headerFileUpload">
                <div className="fileUploadLogoCircle">
                    <i class="fa fa-folder-open"></i>
                </div>
            </div>
            <div className="fileUploadModalBody">
                <p className="fileUploadTitle">Carregue seu arquivo aqui</p>
                <button
                    className={`fileUploadArea ${isDragOver ? "dragOver" : ""}`}
                    onClick={triggerFileInput}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onDragLeave={handleDragLeave}
                >
                    <span className="fileUploadAreaIcon">
                        <i class="fa fa-file-import"></i>
                    </span>

                    <span className="fileUploadAreaTitle">
                        Solte um arquivo aqui para fazer o upload
                    </span>
                    { 
                        file ? (
                            <div className="fileInfo">
                                <p>Arquivo: {file}</p>
                            </div>
                        ) 
                        :
                        (
                            <span className="fileUploadAreaDescription">
                                Alternativa, você pode selecionar um arquivo{" "}
                                <strong>clicando aqui</strong>
                            </span>
                        )
                    }
                </button>
                <input
                    type="file"
                    id="fileInput"
                    className="fileUploadInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
            </div>
            <div className="containerFooterFileUpload">
                <Button
                    classaditional="buttonFileUpload"
                    name={disableButton ? "aguarde..." : "Anexar arquivo"}
                    disabled={disableButton}
                    onClick={() => {
                        handleUploadClick();
                        setdDisableButton(true);
                    
                        setTimeout(() => {
                            setdDisableButton(false);
                        }, 4000);
                    }}
                />
            </div>
        </div>
    );
};

export default FileUpload;
