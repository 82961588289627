import React, { Component, Fragment } from "react";

import "./ActivityForm.css";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    PHONE_TYPE_ACTIVITY,
    VISIT_TYPE_ACTIVITY,
    TASK_TYPE_ACTIVITY,
    HISTORIC_TYPE_ACTIVITY,
    FILES_TYPE_ACTIVITY,
    STATUS_BUSINESS_LOSS,
    STATUS_BUSINESS_GAIN,
    STATUS_BUSINESS_PAUSED,
    ID_BRAND_NOT_VERIFY_DATE_START_VISIT
} from "../../core/constants";
import { formatedTime, validTime, formatedMoneyBRA, formatedOnlyNumber, formatedMoneyBRAToFloat, formatedMoneyBRAFromFloat, formatedDateTimeToUSA, getDateTimeCurrentBrowserAtMidnight, formatedDateTimeToBrowserAtMidnight, dateDiff, getNextDateTimeCurrentBrowserAtMidnight, formatedPercentageNumberFromFloat, anonymizeName } from "../../auxiliary/generalFunctions";

import InputGeneral from "../general/InputGeneral";
import DropDown from "../../components/general/DropDown";
import Menus from "../general/Menus";
import ButtonIcon from "../general/ButtonIcon";
import InputArea from "../general/InputArea";
import Button from "../general/Button";
import Select from "../general/Select";
import Message from "../general/Message";
import TruncatedText from "../general/TruncatedText";
import MessageConfirmation from "../general/MessageConfirmation";
import LargeModal from "../general/LargeModal";
import Loading from "../general/Loading";
import CustomCarousel from "../general/CustomCarousel";

import ActivityController from "../../controllers/ActivityController";
import BusinessController from "../../controllers/BusinessController";
import BusinessAnalysisController from "../../controllers/BusinessAnalysisController";
import FinancialServicesController from "../../controllers/FinancialServicesController";
import DiscountReasonsController from "../../controllers/DiscountReasonsController";
import ServiceController from "../../controllers/ServiceController";

import FinancialServices from "../../assets/others/financial-services-class/assets/js/FinancialServices";

const initialState = {
    // start data task
    task: "INFORMAÇÃO",
    type: HISTORIC_TYPE_ACTIVITY, //phone, meeting , deadline (prazo), mail, lunch (almoço) OR task.
    dateStart: "",
    timeStart: "07:00",
    dateEnds: "",
    timeEnds: "00:00",
    annotations: "",
    file: null,
    filePath: null,
    finished: false,
    isHistoric: true,
    // ends data task

    // start info business
    title: "",
    name: "",
    // ends info business

    menus: [
        {
            option: 0,
            name: "Informação",
            value: HISTORIC_TYPE_ACTIVITY,
            icon: "far fa-clock"
        },
        {
            option: 1,
            name: "Visita",
            value: VISIT_TYPE_ACTIVITY,
            icon: "fas fa-user-friends"
        },
        {
            option: 2,
            name: "Tarefa",
            value: TASK_TYPE_ACTIVITY,
            icon: "fas fa-thumbtack"
        },
        // {
        //     option: 3,
        //     name: "E-mail",
        //     value: MAIL_TYPE_ACTIVITY,
        //     icon: "far fa-paper-plane"
        // },
        {
            option: 3,
            name: "Ligar",
            value: PHONE_TYPE_ACTIVITY,
            icon: "fas fa-phone-alt"
        },
    ],
    menusActive: 0,
    contentOption: 0,
    intervalsTime: [],
    showIntervalsStart: false,
    showIntervalsEnds: false,
    optionsOwnersLead: [
        {
            name: "Maria G. Jesus",
            value: "Maria G. Jesus",
            checked: true
        },
        {
            name: "Pedro Gustavo",
            value: "Pedro Gustavo",
            checked: false
        },
        {
            name: "Lucas Santos",
            value: "Lucas Santos",
            checked: false
        },
    ],
    optionsPhonesClient: [
        {
            name: "(11) 9 1111-2222",
            value: "11911112222",
            checked: true
        },
    ],
    optionsTypeServices: [],
    optionsInstallments: [],
    installmentElements: [],
    installmentsAvailable: [],
    enableInstallmentsGroup: true,
    optionsFinancialInstitution: [],

    // start fiels values "Análise"
    idAnalysis: null,
    newDiscount: false,
    newAnalysis: false,
    businessIdServiceERP: null,
    serviceId: null,
    officeId: null,
    handleSimulateInstallments: false,
    choosenService: null,
    hasInstallments: false,
    changeInstallment: false,
    choosenInstallment: {
        "name": "Selecione uma parcela",
        "value": null
    },
    error: false,
    haveAdiscountRequestInProgress: false,
    haveAdiscountRequestInProgressOrDiscountApproved: false,
    choosenFinancialInstitution: null,
    amountFinanced: "",
    amountMinService: "",
    
    totalInstallments: "", //qtd de parcelas
    totalInstallmentsPaid: "", //qtd de parcelas pagas
    simulateInstallments: "", //qtd de parcelas simuladas
    amountInstallment: "", //valor da parcela
    
    idDiscount: null,
    discounts: [],
    disableCreateOrEditService: false,
    disableButtonRefreshAnalysis: false,
    permissionDiscountApproved: null,
    descriptionDiscount: "",
    optionsDiscountReason: [],
    choosenDiscountReason: null,
    statusApprovalDiscount: null,
    enableEditDiscount: false,
    idDiscountEnabledEdit: null,
    disableButtonRequestDiscount: false,
    discountApplied: false,
    statusApprovalDiscountIcon: {
        1: {
            // icon: "fa fa-hourglass-end buttonIconSpacingActivityForm",
            name: "abatimento solicitado",
            color: "#FFA500"
        },
        2: {
            // icon: "fa fa-check-circle-o buttonIconSpacingActivityForm",
            name: "aprovado" ,
            color: "#28A745" 
        },
        3: {
            // icon: "fa fa-times buttonIconSpacingActivityForm",
            name: "negado",
            color: "#DC3545"
        },
        4: {
            // icon: "fa fa-paper-plane buttonIconSpacingActivityForm",
            name: "enviar para aprovação",
            color: "#317ae2"
        }
    },
    discount: "",
    typeButtonDiscount: 0,

    custeFinancing: "", //custo do financimaneto
    feesFinancing: "", //juros do financiamento
    feesSpecialFinancing: "", //taxas especiais

    successStories: [],
    enableSuccessStories: false,

    valuePayed: "", //valor já pago
    totalInstallmentsNotPaid: "", //parcelas em aberto
    valueDebit: "", //valor da dívida
    amountToHit: "", //valor para quitar
    amountService: "", //valor do serviço
    amountServiceDiscount: "", //valor do serviço com desconto
    amountService1x: "",
    amountService2x: "",
    amountService3x: "",

    needClickOnButtonToSimulate: false,

    noticeMinimunAmountEntry: false, //entrade de?
    amountEntry: "", //entrade de?
    percentEntry: "",
    labelEntry: "",
    styleEntry: "",

    enableGenerateAnalysis: false,
    
    // ends fiels values "Análise"

    // start content money
    validMoney: true,
    // ends content money

    message_type: "information",
    message_show: false,
    message: "",

    isDisabledButtonCreateService: false,
    isDisabledButton: false,
    serviceComplete: false,
    textButtonSaveInstallments: false,
    showButtonSaveInstallments: false,

    // start message confirmantion modal
    message_show_confirmation: false,
    show_children_confirmation: false,
    show_modal_on_action_form: false,
    // ends message confirmantion modal

    carouselItems: [],
    carouselItemsActive: false,

    dateMin: null,
    dateMax: null
}

const activityController = new ActivityController();
const businessController = new BusinessController();
const businessAnalysisController = new BusinessAnalysisController();
const financialServicesController = new FinancialServicesController();
const discountReasonsController = new DiscountReasonsController();
const serviceController = new ServiceController();

const financialServicesClass = new FinancialServices();

class ActivityForm extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let {name, title} = this.props.business | {name: "", title: ""};
        let contentOption = this.props.contentOption;
        const { businessService } = this.props;
        await this.setState({name, title, contentOption});
        this.props.user.PER_ID_USER_ERP = 790; // #testing

        if (businessService)
        {
            this.setState({
                choosenService: {
                    value: businessService.id_service_erp,
                    name: businessService.name
                }
            });
        } 
        else 
        {
            this.setState({
                choosenService: {
                    value: '',
                    name: ''
                },
                serviceComplete: this.props.serviceComplete
            });
        }

        this.generateIntervalTime();
    }

    componentDidUpdate = async (prevProps) =>
    {
        let {name, title} = this.props.business | {name: "", title: ""};

        if (prevProps.businessService !== this.props.businessService) {
            this.setState({
                choosenService: {
                    value: this.props.businessService.value,
                    name: this.props.businessService.name
                }
            });
        }
        
        if (prevProps.idService !== null & prevProps.idService !== this.props.idService) {
            this.props.idService = prevProps.idService;
        }

        if (prevProps.serviceComplete !== null & prevProps.serviceComplete !== this.props.serviceComplete) {
            this.props.serviceComplete = prevProps.serviceComplete;
            this.setState({ serviceComplete: this.props.serviceComplete })
        }

        if (this.props.contentOption !== this.state.contentOption)
        {
            await this.setState({contentOption: this.props.contentOption});

            if (this.props.contentOption === 4) {
                await this.loaderFunctions();

                if (this.state.idAnalysis === null)
                {
                    localStorage.setItem("installment", "");
                }
            
                if (localStorage.getItem("installment") !== "undefined" && localStorage.getItem("installment") !== null && localStorage.getItem("installment") !== "")
                {
                    await this.setInstallments();
                }
                
                const responseFinService = await financialServicesController.getFinServiceByIdService(this.props.businessService.id_financial_service === undefined ? this.props.businessService.value : this.props.businessService.id_financial_service);
                await this.setState({ amountMinService: responseFinService.data.FIN_MINIMUM_AMOUNT });
            }
        }

        if (name !== this.state.name  || title !== this.state.title)
        {
            await this.setState({name, title});
        }
    }

    generateIntervalTime = async () =>
    {        
        let intervalsTime = [];

        for (let i = 7; i < 20; i++) //looping in hours
        {
            let hour = i.toString().padStart(2, "0");
            let minutes = 0;

            for (let j = 0; j <= 3; j++) 
            {
                minutes = (j*15).toString().padStart(2, "0");
                intervalsTime.push([hour, minutes].join(":"));
            }
        }
        intervalsTime.push(["20", "00"].join(":"));
        this.setState({intervalsTime});
    }

    message = (type, message) =>
    {
        this.setState({
            message_type: type,
            message: message,
            message_show: true
        })
    }

    file = () => {
        document.getElementById('fileActivityForm').click();
        document.getElementById('fileActivityForm').onchange = () => {
            let fileInput = document.getElementById('fileActivityForm');
            let file = fileInput.files[0].name;
            this.setState({file, filePath: fileInput.files[0] });
        }
    } 
    
    zeroPad = (num, places) => String(num).padStart(places, '0')

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    
    getNameStatusBusiness = (slug) =>
    {
        switch (slug) {
            case null:
                return "EM ANDAMENTO";
            case STATUS_BUSINESS_GAIN:
                return "GANHO";
            case STATUS_BUSINESS_LOSS:
                return "PERDIDO";
            case STATUS_BUSINESS_PAUSED:
                return "PAUSADO";
            default:
                return "";
        }
    }

    getDiscountByIdBusinessAnalysis = async() =>
    {
        if(this.state.idAnalysis === null) { return;}
        const result = await businessAnalysisController.getDiscountByIdBusinessAnalysis(this.state.idAnalysis);
        
        this.setState({ enableEditDiscount: false, disableButtonRequestDiscount: false, haveAdiscountRequestInProgressOrDiscountApproved: false, statusApprovalDiscount: null, discount: "" });
        
        if (!result.status)
        {
            this.setState({ discounts: [] });
            return;
        }

        this.setState({ discounts: result.data })
        let haveAdiscountRequestInProgress = false;

        result.data.forEach(DISCOUNT => {
            if (DISCOUNT.BAD_STATUS !== 3)
            {
                this.setState({ 
                    discount: formatedMoneyBRAFromFloat(parseFloat(DISCOUNT.BAD_AMOUNT_DISCOUNT))
                })
            }
            
            if (DISCOUNT.BAD_STATUS === 4)
            {
                this.setState({ enableEditDiscount: true, idDiscountEnabledEdit: DISCOUNT.BAD_ID });
            }

            if (DISCOUNT.BAD_STATUS === 2)
            {
                this.setState({ disableButtonRequestDiscount: true });
            }

            if (DISCOUNT.BAD_STATUS === 1 || DISCOUNT.BAD_STATUS === 4)
            {
                haveAdiscountRequestInProgress = true;
            }

            if (DISCOUNT.BAD_STATUS === 1)
            {
                this.setState({ disableButtonRequestDiscount: true });
            }

            if (DISCOUNT.BAD_STATUS !== 3)
            {
                this.setState({haveAdiscountRequestInProgressOrDiscountApproved: true});
            }

            this.setState({ 
                statusApprovalDiscount: DISCOUNT.BAD_STATUS,
                haveAdiscountRequestInProgress: haveAdiscountRequestInProgress
            })
        });
        
    }

    getAnalysisFromBusiness = async() =>
    {
        if (!this.props.business.id)
        {
            return;
        }

        if (this.state.idAnalysis && !this.state.disableButtonRefreshAnalysis)
        {
            return;
        }

        const businessId = this.props.business.id;
        const result = await businessController.getById(businessId);
        
        if (!result.status)
        {
            return;
        }
        
        if (!result.data.BAN_ID)
        {
            return;
        }

        const choosenFinancialInstitution = {
            name: result.data.BAN_NAME_FINANCIAL_INSTITUTION_FROM_ERP,
            value: result.data.BAN_ID_FINANCIAL_INSTITUTION_FROM_ERP
        };

        const choosenService = this.state.optionsTypeServices.find(s => s.value === parseInt(result.data.BAN_ID_FINANCIAL_SERVICE));

        await this.setState({
            idAnalysis: result.data.BAN_ID,
            choosenFinancialInstitution,
            choosenService,
            amountFinanced: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_FINANCED)),
            totalInstallments: result.data.BAN_TOTAL_INSTALLMENTS,
            totalInstallmentsPaid: result.data.BAN_TOTAL_INSTALLMENTS_PAID,
            officeId: result.data.UNT_ID_COMPANY_UNIT_ERP,
            amountInstallment: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_INSTALLMENT)),
            feesSpecialFinancing: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_SPECIAL_FEES)),
            amountServiceDiscount: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_SERVICE_DISCOUNT)),
            amountEntry: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_ENTRY)),
            custeFinancing: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_TOTAL_FINANCING)),
            valuePayed: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_TOTAL_PAID_FINANCING)),
            feesFinancing: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_FEES_FINANCING)),
            totalInstallmentsNotPaid: result.data.BAN_TOTAL_INSTALLMENTS_NOT_PAID,
            valueDebit: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_NOT_PAID_FINANCING)),
            amountToHit: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_TO_HIT)),
            amountService: formatedMoneyBRAFromFloat(parseFloat(result.data.BAN_AMOUNT_SERVICE))
        });

        if(this.state.idAnalysis !== null) { await this.getDiscountByIdBusinessAnalysis(); }

        if (result.data.BAN_AMOUNT_SIMULATION_INSTALLMENTS > this.state.installmentsAvailable)
        {
            this.setState({ installmentsAvailable: result.data.BAN_AMOUNT_SIMULATION_INSTALLMENTS });
            await this.props.transferAnotherInstallmentToAnotherComponent(result.data.BAN_AMOUNT_SIMULATION_INSTALLMENTS);
            await this.getNumberOfInstallments();
            await this.hasInstallments();
            this.setState({ choosenInstallment: this.state.optionsInstallments.find((e) => e.value === result.data.BAN_AMOUNT_SIMULATION_INSTALLMENTS) });
        }
        else
        {
            this.setState({ choosenInstallment: this.state.optionsInstallments.find((e) => e.value === result.data.BAN_AMOUNT_SIMULATION_INSTALLMENTS) });
        }

        if (!this.state.changeInstallment && this.state.choosenInstallment.value > 0 && this.state.choosenInstallment.value !== null)
        {
            this.setState({ handleSimulateInstallments: true });
            this.simulateInstallments();
            await this.hasInstallments();
        }

        await this.getAnalysisFromClass();
    }

    getAnalysisFromClass = async() =>
    {
        const service = this.state.optionsTypeServices.find(s => s.value === this.state.choosenService.value);
        const responseFinService = await financialServicesController.getFinServiceByIdService(this.props.businessService.id_financial_service === undefined ? this.props.businessService.value : this.props.businessService.id_financial_service);
        const idAnalysis = this.state.idAnalysis;
        const newDiscount = this.state.newDiscount;
        const newAnalysis = this.state.newAnalysis;
        const newState = {};

        const analysis = financialServicesClass.calculateCostFinancialService({
            service,
            newDiscount,
            newAnalysis,
            idAnalysis,
            amountEntryOld: this.state.amountEntry,
            totalInstallments: parseInt(this.state.totalInstallments), 
            amountInstallments: formatedMoneyBRAToFloat(this.state.amountInstallment), 
            discount: formatedMoneyBRAToFloat(
                (
                  isNaN(formatedMoneyBRAToFloat(this.state.discount)) ||
                  this.state.discount === null ||
                  (this.state.discount === "" && this.state.statusApprovalDiscount === 3)
                ) ? "0,00" : this.state.discount
            ),
            amountFinanced: formatedMoneyBRAToFloat((isNaN(formatedMoneyBRAToFloat(this.state.amountFinanced)) || this.state.amountFinanced === null || this.state.amountFinanced === "") ? "0,00" : this.state.amountFinanced),
            totalInstallmentsPaid: parseInt((isNaN(parseInt(this.state.totalInstallmentsPaid)) || this.state.totalInstallmentsPaid === null || this.state.totalInstallmentsPaid === "") ? "0" : this.state.totalInstallmentsPaid),
            amountSpecialFees: formatedMoneyBRAToFloat((isNaN(formatedMoneyBRAToFloat(this.state.feesSpecialFinancing)) || this.state.feesSpecialFinancing === null) ? "0,00" : this.state.feesSpecialFinancing),
            amountServiceByLongTimeInputValue: formatedMoneyBRAToFloat(this.state.amountEntry),
            amountMinService: parseFloat(responseFinService.data.FIN_MINIMUM_AMOUNT),
            finPercentFees: parseFloat(responseFinService.data.FIN_PERCENT_FEES),
        });

        if (analysis.status !== 200)
        {
            return analysis;
        }

        for (let index = 1; index <= analysis.data.amountServiceByLongTimeInstallments.length; index++) {
            const amountServiceValue = formatedMoneyBRAFromFloat(analysis.data.amountServiceByLongTimeInstallments[index - 1].installment);

            newState[`amountService${index}x`] = amountServiceValue;
        }

        let percentEntry = analysis.data.percentEntry;
        let labelEntry = "";
        let styleEntry = "";

        if (percentEntry === 100)
        {
            labelEntry = "Excelente";
            styleEntry = "success";
        }
        else if (percentEntry >= 90)
        {
            labelEntry = "Ótimo";
            styleEntry = "success";
        }
        else if (percentEntry >= 70)
        {
            labelEntry = "Muito Bom";
            styleEntry = "primary";
        }
        else if (percentEntry >= 40)
        {
            labelEntry = "Bom";
            styleEntry = "info";
        }
        else if (percentEntry >= 30)
        {
            labelEntry = "Razoável";
            styleEntry = "warning";
        } else {
            labelEntry = "Ruim";
            styleEntry = "danger";
        }

        percentEntry = percentEntry.toFixed(2);

        await this.setState({
            ...newState,
            amountEntry: formatedMoneyBRAFromFloat(analysis.data.amountServiceByLongTimeInputValue),
            percentEntry,
            labelEntry,
            styleEntry
        });

        return analysis;
    }

    getDiscountReasons = async () =>
    {
        const result = await discountReasonsController.get();
        if (!result.status) {
            return;
        }

        let optionsDiscountReason = [];
        for (const financial of result.data.data)
        {
            optionsDiscountReason.push({
                name: financial.DRE_TITLE,
                value: financial.DRE_ID,
            });
        }
        
        this.setState({ optionsDiscountReason });
    }

    deleteDiscount = async (id) => {
        const data = {
            id: id
        }

        const result = await businessAnalysisController.deleteDiscount(data);
        if (!result.status)
        {
            return;
        }

        await this.loaderFunctions();
        await this.onActionFormAnalysis();
    }

    updateDataDiscount = async (id) => {
        const data = {
            id: id,
            discount: formatedMoneyBRAToFloat(this.state.discount),
            reason: this.state.choosenDiscountReason.value,
            description: this.state.descriptionDiscount
        }

        const result = await businessAnalysisController.updateStatusDiscount(data);
        if (!result.status)
        {
            return;
        }

        this.setState({
            message_show_confirmation: false,
        });

        await this.getDiscountByIdBusinessAnalysis();
    }

    updateStatusDiscount = async (id, status) =>
    {
        const data = {
            id: id,
            status: status,
        }

        const result = await businessAnalysisController.updateStatusDiscount(data);
        if (!result.status)
        {
            return;
        }

        if(status === 3)
        {
            await this.setState({ discount: "" })
            await this.onActionFormAnalysis();
        }

        await this.getDiscountByIdBusinessAnalysis();

        let message = ""
        
        if (status === 1)
        {
            message = "Abatimento foi solicitado com sucesso!"
        }
        else if (status === 2)
        {
            message = "Abatimento aprovado com sucesso!"
        }
        else if (status === 3)
        {
            message = "Abatimento negado com sucesso!"
        }

        return this.message("success", message)
    }

    getFinancialServices = async () =>
    {
        const result = await financialServicesController.get();
        if (!result.status) {
            return;
        }

        let optionsTypeServices = [];
        for (const financial of result.data.data)
        {
            optionsTypeServices.push({
                name: financial.FIN_NAME,
                value: financial.FIN_ID,
                percentRateService: parseFloat(financial.FIN_PERCENT_RATE),
                percentFeesService: parseFloat(financial.FIN_PERCENT_FEES),  
                checked: false
            });
        }
        
        this.setState({ optionsTypeServices });
    }

    sendDiscountToApproval = async () =>
    {
        const data = { 
            discount: formatedMoneyBRAToFloat(this.state.discount),
            idAnalysis: this.state.idAnalysis,
            reason: this.state.choosenDiscountReason.value,
            description: this.state.descriptionDiscount
        }

        const result = await businessAnalysisController.sendDiscountToApproval(data);
        if (!result.status)
        {
            return;
        }

        this.setState({
            message_show_confirmation: false,
        });

        await this.getDiscountByIdBusinessAnalysis();
    }

    getSuccessStories = async () =>
    {

        if (!this.state.choosenFinancialInstitution || !this.state.choosenFinancialInstitution.value) { return; }
        const result = await serviceController.getSuccessStories({ 
            financialId: this.state.choosenFinancialInstitution.value,
            officeId: this.state.officeId
        });
        
        if (!result.status)
        {
            this.setState({ enableSuccessStories: false });
            return;
        }

        let successStories = [];
        if(result.data.data.data)
        {
            for (const stories of result.data.data.data)
            {
                successStories.push({
                    name: stories.clientName,
                    media: stories.photoVideo,
                    bank: stories.bank,
                    debtValue: "R$ " + formatedMoneyBRA(stories.debitValue),
                    settledValue: "R$ " + formatedMoneyBRA(stories.discountValue),
                    discount: formatedPercentageNumberFromFloat(stories.discountPercentage) + "%",
                });
            }
            
            this.setState({ successStories, enableSuccessStories: true });
        }
    }

    getInstallmentsAvailable = async () =>
    {
        const result = await businessController.getInstallmentsAvailable(this.props.user.PER_ID_PERMISSIONS_GROUP);
        if (!result.status || result.data.length === 0) {
            return;
        }

        let installmentsAvailable = result.data[0].BAI_AMOUNT_MAXIMUM_INSTALLMENTS;
        let permissionDiscountApproved = result.data[0].BAI_GROUP_WITH_PERMISSION_TO_APPROVED;

        this.setState({ installmentsAvailable, permissionDiscountApproved });
    }

    getNumberOfInstallments = async () =>
    {
        let optionsInstallments = [];

        optionsInstallments.push({
            name: `À VISTA`,
            value: 0
        });

        for (let number = 1; number <= this.state.installmentsAvailable; number++)
        {
            optionsInstallments.push({
                name: `${number}x`,
                value: number
            });
        }

        this.setState({ optionsInstallments });
    }

    positionInstallmentDate = async (date, currentInstallment) => {
        if (!this.state.choosenInstallment) { return; }
        const installments = this.state.choosenInstallment.value;
        let dateString = date + "T00:00:00";
        const baseDate = new Date(dateString);
        const originalDay = baseDate.getDate();
    
        for (let index = currentInstallment + 1; index <= installments; index++)
        {
            const paymentDate = new Date(baseDate.getFullYear(), baseDate.getMonth() + (index - currentInstallment), originalDay);
    
            if (paymentDate.getDate() !== originalDay) paymentDate.setDate(0);
    
            const formattedDate = paymentDate.toISOString().split('T')[0];
            document.getElementById(`installmentDate${index}`).value = formattedDate;
        }
    }

    loaderFunctions = async () => {
        await this.getInstallmentsAvailable();
        await this.getNumberOfInstallments();
        await this.getFinancialServices();
        await this.getDiscountReasons();
        await this.getFinancialInstitution();
        await this.getAnalysisFromBusiness();
        await this.getDiscountByIdBusinessAnalysis();
        await this.getSuccessStories();
    }
    
    setInstallments = async () => {
        if(!this.state.amountEntry) return;
        if(!this.state.choosenInstallment) return;

        this.setState({ installmentElements: [] });
        
        const installmentElements = [];
        const installments = this.state.choosenInstallment.value;

        installmentElements.push(
            <div className={`row no-gutters lineActivityForm`} style={{paddingBottom: 10, borderBottom: `1px solid #cfd4d9` }}>
                <div
                    style={{fontSize: 20}}
                    className="col-3 columnActivityForm d-flex align-items-center"
                    >
                    <span>ENTRADA</span>
                </div>

                <div
                    className="col-6 columnActivityForm pl-2 pr-2 d-flex align-items-center"
                    >
                    <span style={{textAlign: "center", color: "#fff", fontWeight: 400}} className={`badge bg-${this.state.styleEntry} activityFormTextResultAnalysis`}>{ this.state.percentEntry && `${this.state.percentEntry} % - ${this.state.labelEntry}` }</span>
                </div>

                <div className="col-3 columnActivityForm">
                    <InputGeneral
                        disabled
                        maxLength={12}
                        mandatory="true"
                        value={this.state.amountEntry}
                        id={`installmentServiceValueEntry`}
                        valid={this.state.validMoney.toString()}
                        style={{textAlign: "right", paddingRight: 10, height: 50, fontSize: 20}}
                    />
                </div>
            </div>
        );
      
        for (let index = 1; index <= installments; index++) {
            installmentElements.push(
                <div 
                    key={index} 
                    className={`row no-gutters lineActivityForm`} 
                    style={{
                        paddingBottom: 10, 
                        borderBottom: index !== installments ? `1px solid #cfd4d9` : 'none'
                    }}
                >
                    <div
                        style={{ fontSize: 20 }}
                        className="col-3 columnActivityForm d-flex align-items-center"
                    >
                        <span>{`PARCELA ${index}`}</span>
                    </div>
        
                    <div
                        className="col-6 columnActivityForm pl-2 pr-2 d-flex align-items-center"
                    >
                    </div>
        
                    <div className="col-3 columnActivityForm">
                        <InputGeneral
                            maxLength={12}
                            mandatory="true"
                            id={`installmentValue${index}`}
                            valid={this.state.validMoney.toString()}
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/\D/g, "");
                                if (value === "") value = "0";
                                value = parseInt(value, 10).toString().padStart(3, "0");
                                const formattedValue = formatedMoneyBRA(value);
                                e.target.value = formattedValue;
                                this.calcInstallments(index);
                            }}
                            style={{
                                textAlign: "right", 
                                paddingRight: 10, 
                                height: 50, 
                                fontSize: 20
                            }}
                        />
                    </div>
                </div>
            );
        }
        
        await this.setState({ installmentElements });
        // if (this.state.enableInstallmentsGroup === true) { await this.getInstallmentsOnStorage(); }
        await this.calcInstallments();
    };

    getInstallmentsOnStorage = async () => {
        const installments = this.state.installmentsAvailable;
        const installmentsGroup = JSON.parse(localStorage.getItem('installmentsGroup')) || [];

        for (let index = 1; index <= installments; index++) {
            if (document.getElementById(`installmentValue${index}`))
            {
                document.getElementById(`installmentValue${index}`).value = formatedMoneyBRAFromFloat(installmentsGroup[index - 1]);
            }
        }
    }
    
    hasInstallments = async () => 
    {
        this.setState({ hasInstallments: false })
        let result = await serviceController.hasInstallments(this.props.idService);
        if (!result.status) { return; }

        if ( result.data.dividas !== undefined && result.data.dividas.length > 0 )
        {
            this.setState({ hasInstallments: true })
        }
    }

    calcInstallments = async (currentInstallment) => {
        if(!this.state.amountEntry) return;
        
        let amountService = parseFloat(this.state.amountService.replace(/\./g, '').replace(',', '.'));
        amountService = amountService - this.state.amountEntry.replace(/\./g, '').replace(',', '.');

        let numInstallments = this.state.choosenInstallment.value;
        let newAmountService = 0;

        if(currentInstallment)
        {
            for (let index = 1; index <= currentInstallment; index++) 
            {
                newAmountService = newAmountService + parseFloat(document.getElementById(`installmentValue${index}`).value.replace(/\./g, '').replace(',', '.'));
                numInstallments = numInstallments - 1;
            }
    
            amountService = amountService - newAmountService
        }

        let amountBaseInstallment = Math.floor((amountService / numInstallments) * 100) / 100;
        let currentTotal = amountBaseInstallment * numInstallments;
        let diff = (amountService - currentTotal).toFixed(2);
        let installments = new Array(numInstallments).fill(amountBaseInstallment);

        installments[numInstallments - 1] = formatedMoneyBRA((installments[numInstallments - 1] + parseFloat(diff)).toFixed(2));

        let indexOcasionally = (currentInstallment) ? currentInstallment : 1;
        let indexInstallmentValue = 0;
        
        for (let index = indexOcasionally; index <= this.state.installmentsAvailable; index++)
        { 
            if (this.state.enableInstallmentsGroup === false)
            {
                if(currentInstallment && index > currentInstallment)
                {
                    if(document.getElementById(`installmentValue${index}`)) 
                    {
                        document.getElementById(`installmentValue${index}`).value = formatedMoneyBRAFromFloat(installments[indexInstallmentValue]);
                        indexInstallmentValue++
                    }
                }

                if(currentInstallment === null || currentInstallment === undefined)
                {
                    if(document.getElementById(`installmentValue${index}`)) 
                    {
                        document.getElementById(`installmentValue${index}`).value = formatedMoneyBRAFromFloat(installments[index - 1]);
                    }
                }
            }
        }

        if (this.state.enableInstallmentsGroup === false)
        {
            let installmentsArray = [];
            for (let index = 1; index <= this.state.installmentsAvailable; index++) {
                if (document.getElementById(`installmentValue${index}`)) {
                    let value = parseFloat(
                        document.getElementById(`installmentValue${index}`).value.replace(/\./g, '').replace(',', '.')
                    );
                    installmentsArray.push(value);
                }
            }
            localStorage.setItem('installmentsGroup', JSON.stringify(installmentsArray));
        }

        this.setState({ enableInstallmentsGroup: false });
    }

    simulateInstallments = async () => {
        await this.onActionFormAnalysis();
        await this.setInstallments();
        this.setState({ needClickOnButtonToSimulate: false })
    }

    saveInstallments = async () => {
        const installments = this.state.choosenInstallment.value;
        const installmentsValues = [];

        this.setState(prevState => ({
            textButtonSaveInstallments: !prevState.textButtonSaveInstallments
        }));

        for (let index = 1; index <= installments; index++) {
            installmentsValues.push(document.getElementById(`installmentValue${index}`).value);

            (document.getElementById(`installmentDate${index}`).hasAttribute("disabled")) 
            ? document.getElementById(`installmentDate${index}`).removeAttribute("disabled") 
            : document.getElementById(`installmentDate${index}`).setAttribute("disabled", true);

            (document.getElementById(`installmentValue${index}`).hasAttribute("disabled")) 
            ? document.getElementById(`installmentValue${index}`).removeAttribute("disabled") 
            : document.getElementById(`installmentValue${index}`).setAttribute("disabled", true);
        }

        await businessAnalysisController.saveInstallments({BAN_ID: this.state.idAnalysis, installments: installmentsValues});
        
        return this.message("success", "Parcelas geradas com sucesso!");
    }

    getFinancialInstitution = async () =>
    {
        const result = await serviceController.getOptions();
        if (!result.status) {
            return;
        }

        await this.setState({
            optionsFinancialInstitution: result.data.optionsFinancialInstitution,
        });
    }

    getServiceType = async () =>
    {
        const result = await serviceController.getOptions();
        if (!result.status) {
            return;
        }

        await this.setState({
            optionsTypeServices: result.data.optionsServiceType
        });
    }

    onActionForm = async () =>
    {
        await this.props.onSaveActivity();
        if ([STATUS_BUSINESS_GAIN, STATUS_BUSINESS_LOSS].includes(this.props.business.status))
        {
            return this.message("error", `Não é permitido criar atividade em um negócio ${this.getNameStatusBusiness(this.props.business.status)}!`);
        }

        const hasFile = this.state.filePath !== null ? true : false;
        let businessId = null;
        let clientId = null;
        let file = null;
        let title = this.state.task;

        let finished = 2;

        if (this.props.business.id)
        {
            businessId = this.props.business.id;
            clientId = this.props.business.clientId;
        }

        if (title === "" && !hasFile)
        {
            return this.message("error", "Informe o título!");
        }
        
        if (this.state.type === HISTORIC_TYPE_ACTIVITY)
        {
            finished = 1;
            await this.setState({dateStart: null, timeStart: null});
        }
        else
        {
            if (!this.state.dateStart)
            {
                return this.message("error", "Informe a data da atividade!");
            }

            if (this.state.type === VISIT_TYPE_ACTIVITY) 
            {
                const clientCompanyUnit = this.props.business.clientCompanyUnit;
                const companyUnit = this.props.user.unit_companies.find(u => u.UNT_ID === clientCompanyUnit);
                let verify = true;
        
                if (companyUnit?.BNT_ID === ID_BRAND_NOT_VERIFY_DATE_START_VISIT) 
                {
                    verify = false;
                }

                if (verify && (this.state.dateStart !== this.state.dateMin && this.state.dateStart !== this.state.dateMax))
                {
                    return this.message("error", "Data inválida!");
                }
            }
        }

        if (hasFile)
        {
            title = "Arquivos";

            file = {
                name: this.state.filePath.name,
                size: this.state.filePath.size,
                src: await this.toBase64(this.state.filePath)
            }

            await this.setState({
                type: FILES_TYPE_ACTIVITY
            });

            finished = 1;
        }

        if ((this.state.timeStart === "" || this.state.timeStart === null) && (this.state.type !== HISTORIC_TYPE_ACTIVITY && this.state.type !== FILES_TYPE_ACTIVITY))
        {
            return this.message("error", "Informe o horário!");
        }

        if (this.state.annotations === "" && this.state.type !== FILES_TYPE_ACTIVITY)
        {
            return this.message("error", "Descreva a atividade!");
        }

        if (this.state.type === VISIT_TYPE_ACTIVITY)
        {
            const resultActivities = await activityController.getByBusiness(this.props.user.PER_ID, this.props.business.id);
            const activities = resultActivities.data.data;

            let dateInstanceToday = getDateTimeCurrentBrowserAtMidnight();
            let hasActivityVisitInPrgress = false;
            let hasOpenedActivityVisitInProgress = false;

            activities.forEach((a) => {
                if (a.ACT_TYPE === "VISIT")
                { 
                    let datetimeCreated = a.ACT_CREATE_DATE;
                    let partsDateTimeCreated = datetimeCreated.split(" ");
                    let dateCreated = partsDateTimeCreated[0];
                    let dateInstanceCreated = formatedDateTimeToBrowserAtMidnight(dateCreated);
                    let diffDateCreateToToday = dateDiff.inDays(dateInstanceCreated, dateInstanceToday);
                    let daysToInvalidate = 4;

                    if (diffDateCreateToToday <= daysToInvalidate)
                    {
                        let dateStartActivity = a.ACT_DATE_START;
                        let dateToday = formatedDateTimeToUSA(getDateTimeCurrentBrowserAtMidnight());
                        let dateTomorrow = formatedDateTimeToUSA(getNextDateTimeCurrentBrowserAtMidnight(1));

                        if (dateStartActivity === dateToday || dateStartActivity === dateTomorrow)
                        {
                            hasActivityVisitInPrgress = true;
                            hasOpenedActivityVisitInProgress = (a.ACT_FINISHED === 2);
                        }
                    }
                }
            });

            if (hasActivityVisitInPrgress)
            {
                let extension = (!hasOpenedActivityVisitInProgress) ? " reabri-la e " : "";
                let message = `Opsssss, já existe uma visita agendada para o presente, você deve ${extension} editar a data!`;
                return this.message("error", message);
            }

            if (activities.some(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY && a.ACT_DATE_START === this.state.dateStart && a.ACT_TIME_START === this.state.timeStart)) 
            {
                return this.message("error", "Opsssss, já existe uma visita agendada para este horário!");
            }

            if (activities.some(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY && a.ACT_DATE_START === this.state.dateStart)) 
            {
                return this.message("error", "Opsssss, já existe uma visita agendada para este dia, caso esteja fechada, você deve reabri-la!");
            }
        }

        const result = await activityController.create({
            ACT_TITLE: title,
            ACT_TYPE: this.state.type,
            ACT_DATE_START: this.state.dateStart,
            ACT_TIME_START: this.state.timeStart,
            ACT_ANNOTATIONS: this.state.annotations,
            ACT_ID_BUSINESS: businessId,
            ACT_ID_PERSON_CLIENT: clientId,
            ACT_ID_CREATE_PERSON: this.props.user.PER_ID,
            ACT_FINISHED: finished,
            hasFile,
            file
        });

        if (!result.status)
        {
            return this.message("error", result.message);
        }

        this.setState({
            task: "",
            dateStart: "",
            timeStart: "00:00",
            dateEnds: "",
            timeEnds: "00:00",
            annotations: "",
            file: null,
            filePath: null
        });

        if (this.props.business.id)
        {
            if (hasFile)
            {
                window.location.reload();
            } 
            else
            {
                this.message("success", "Atividade criada com sucesso!");
                await this.props.onSaveActivity();
            }
        }

        if (this.state.show_modal_on_action_form)
        {
            await this.setState({
                message_show: false, 
                message_show_confirmation: true
            });
        }
    }

    onActionFormAnalysis = async () =>
    {
        const businessId = this.props.business.id;
        const business = await businessController.getById(businessId);

        if (business.data.BUS_STATUS === "GAIN" || business.data.BUS_STATUS === "LOSS" || business.data.BUS_STATUS === "PAUSED")
        {
            let nameStatus = "";
            switch (business.data.BUS_STATUS)
            {
                case "GAIN":
                    nameStatus = "GANHO";
                    break;

                case "LOSS":
                    nameStatus = "PERDIDO";
                    break;

                case "PAUSED":
                    nameStatus = "PAUSADO";
                    break;
            
                default:
                    break;
            }

            return this.message("error", `O seguinte status '${nameStatus}' não permite que o negócio gere uma análise.`)

        }

        if(this.props.business.owner === null)
        {
            return this.message("error", "Adicione um proprietário para prosseguir")
        }
        
        if(this.props.business.status !== null)
        {
            return this.message("error", `Não é permitido fazer análise em um negócio ${this.getNameStatusBusiness(this.props.business.status)}!`);
        }

        if (this.state.choosenFinancialInstitution === null)
        {
            return this.message("error", `Não é permitido fazer análise em um negócio sem escolher instituição financeira`);
        }

        if(isNaN(this.state.totalInstallments) || this.state.totalInstallments === null || this.state.totalInstallments === "")
        {
            return this.message("error", `Não é permitido fazer análise sem quantidade de parcelas definidas`);
        }

        if (this.state.amountInstallment === "" || this.state.amountInstallment === null || formatedMoneyBRAToFloat(this.state.amountInstallment) <= 0)
        {
            return this.message("error", `Não é permitido fazer análise em um negócio sem colocar um Valor de Parcela`);
        }
        
        if(formatedMoneyBRAToFloat(this.state.amountFinanced) !== 0 && formatedMoneyBRAToFloat(this.state.amountFinanced) !== "" && formatedMoneyBRAToFloat(this.state.amountFinanced) > formatedMoneyBRAToFloat(this.state.custeFinancing))
        {
            return this.message("error", "Valor financiado deve ser menor que o custo do financiamento.");
        }

        if (parseInt(this.state.totalInstallments) < this.state.totalInstallmentsPaid)
        {
            return this.message("error", "Não foi possível fazer a análise! A quantidade de parcelas não pode ser menor que a quantidade parcelas pagas.")
        }
        
        const analysis = await this.getAnalysisFromClass();

        if (analysis.status !== 200)
        {
            return this.message("error", "Não foi possível fazer a análise! Verifique os parâmetros enviados.");
        }

        if (analysis.data.amountNotPaidFinancing <= 0)
        {
            return this.message("error", "O Valor da Dívida não é aceitável!")
        }

        await this.setState({
            valuePayed: formatedMoneyBRAFromFloat(analysis.data.amountTotalPaidFinancing),
            totalInstallmentsNotPaid: analysis.data.totalInstallmentsNotPaid,
            valueDebit: formatedMoneyBRAFromFloat(analysis.data.amountNotPaidFinancing),
            amountToHit: formatedMoneyBRAFromFloat(analysis.data.amountToHit),
            amountService: formatedMoneyBRAFromFloat(analysis.data.amountService),
            amountServiceDiscount: formatedMoneyBRAFromFloat(analysis.data.amountServiceDiscount),
            custeFinancing: formatedMoneyBRAFromFloat(analysis.data.amountTotalFinancing),
            feesFinancing: formatedMoneyBRAFromFloat(analysis.data.amountFeesFinancing),
        });

        if (!businessId)
        {
            return this.message("error", "Nenhum negócio informado!");
        }

        const data = {
            BAN_ID_BUSINESS: businessId,
            BAN_ID_FINANCIAL_SERVICE: this.state.choosenService.value,
            BAN_ID_FINANCIAL_INSTITUTION_FROM_ERP: this.state.choosenFinancialInstitution.value,
            BAN_NAME_FINANCIAL_INSTITUTION_FROM_ERP: this.state.choosenFinancialInstitution.name,
            BAN_AMOUNT_FINANCED: formatedMoneyBRAToFloat((isNaN(formatedMoneyBRAToFloat(this.state.amountFinanced)) || this.state.amountFinanced === null || this.state.amountFinanced === "") ? "0,00" : this.state.amountFinanced),
            BAN_TOTAL_INSTALLMENTS: parseInt(this.state.totalInstallments),
            BAN_TOTAL_INSTALLMENTS_PAID: parseInt((isNaN(parseInt(this.state.totalInstallmentsPaid)) || this.state.totalInstallmentsPaid === null || this.state.totalInstallmentsPaid === "") ? "0" : this.state.totalInstallmentsPaid),
            BAN_AMOUNT_INSTALLMENT: formatedMoneyBRAToFloat(this.state.amountInstallment),
            BAN_AMOUNT_SPECIAL_FEES: formatedMoneyBRAToFloat((isNaN(formatedMoneyBRAToFloat(this.state.feesSpecialFinancing)) || this.state.feesSpecialFinancing === null || this.state.feesSpecialFinancing === "" || this.state.feesSpecialFinancing === undefined) ? "0,00" : this.state.feesSpecialFinancing),
            BAN_AMOUNT_ENTRY: formatedMoneyBRAToFloat(this.state.amountEntry),
            BAN_AMOUNT_TOTAL_FINANCING: formatedMoneyBRAToFloat(this.state.custeFinancing),
            BAN_AMOUNT_TOTAL_PAID_FINANCING: formatedMoneyBRAToFloat(this.state.valuePayed),
            BAN_AMOUNT_FEES_FINANCING: formatedMoneyBRAToFloat(this.state.feesFinancing),
            BAN_TOTAL_INSTALLMENTS_NOT_PAID: parseInt(this.state.totalInstallmentsNotPaid),
            BAN_AMOUNT_NOT_PAID_FINANCING: formatedMoneyBRAToFloat(this.state.valueDebit),
            BAN_AMOUNT_TO_HIT: formatedMoneyBRAToFloat(this.state.amountToHit),
            BAN_AMOUNT_SERVICE_DISCOUNT: formatedMoneyBRAToFloat(this.state.amountServiceDiscount),
            BAN_AMOUNT_SIMULATION_INSTALLMENTS: parseInt((isNaN(parseInt(this.state.choosenInstallment.value)) || this.state.choosenInstallment.value === null || this.state.choosenInstallment.value === "") ? "0" : this.state.choosenInstallment.value),
            BAN_AMOUNT_SERVICE: formatedMoneyBRAToFloat(this.state.amountService)
        };

        if (this.state.idAnalysis)
        {
            const resultUpdate = await businessAnalysisController.update(this.state.idAnalysis, data);

            if (!resultUpdate.status) {
                return this.message("error", "Não foi possível alterar a análise!");
            }
        }
        else
        {
            const resultCreate = await businessAnalysisController.create(data);

            if (!resultCreate.status) {
                return this.message("error", "Não foi possível gravar a análise!");
            }
        }

        if (this.state.newDiscount === true)
        {
            this.setState({ newDiscount: false })
        }

        await this.props.updateAmountService(analysis.data.amountService);
        await this.getAnalysisFromBusiness();
    }
    
    changeDateMinAndDateMax = async () =>
    {
        let dateMin = null;
        let dateMax = null;

        const type = this.state.type;

        if (type === VISIT_TYPE_ACTIVITY)
        {
            const clientCompanyUnit = this.props.business.clientCompanyUnit;
            const companyUnit = this.props.user.unit_companies.find(u => u.UNT_ID === clientCompanyUnit);
            let verify = true;

            if (companyUnit?.BNT_ID === ID_BRAND_NOT_VERIFY_DATE_START_VISIT) {
                verify = false;
            }

            if (verify)
            {
                await this.setState({ dateStart: "" });
                dateMin = getDateTimeCurrentBrowserAtMidnight();
                dateMin = formatedDateTimeToUSA(dateMin);

                dateMax = getDateTimeCurrentBrowserAtMidnight();

                const indexFromDay = dateMax.getDay();

                if (indexFromDay === 6)
                {
                    dateMax.setDate(dateMax.getDate() + 2);
                }
                else
                {
                    dateMax.setDate(dateMax.getDate() + 1);
                }

                dateMax = formatedDateTimeToUSA(dateMax);
            }
        }

        await this.setState({
            dateMin,
            dateMax
        });

    }

    render ()
    {
        return (
            <div className="activityForm">
                {
                    this.state.loading &&
                    <div className="activityFormLoading">
                        <Loading loading={this.state.loading} />
                    </div>
                }
                <Message
                    message_type={this.state.message_type}
                    message={this.state.message}
                    onClose={() => this.setState({message_show: false})}
                    show={this.state.message_show}
                />
                {
                    (this.state.contentOption === 1) &&
                    <Fragment>
                        <MessageConfirmation
                            message_type="information"
                            message={`Parabéns, ${this.props.user.PER_NAME}!`}
                            onClose={() => this.setState({message_show_confirmation: false})}
                            onClickYes={() => {
                                this.setState({
                                    message_show_confirmation: false,
                                });
                            }}
                            show={this.state.message_show_confirmation}
                            showButtonYes={false}
                        >
                            <p className="text-left">
                                Você cumpriu mais uma etapa do processo. Agora confira se já providenciou os itens abaixo:<br /><br />1 - Solicitar documentação do cliente para confirmar se ele é titular.<br />2 - Se não for titular tem que ter Procuração.<br />3 - Confirmar os dados da dívida (carnê, extrato atualizado ou se ele tem acesso ao aplicativo).<br />4 - Marque o especialista da vez.<br />5 - Encaminhe o cliente para fazer análise junto ao Especialista.
                            </p>
                        </MessageConfirmation>
                    </Fragment>
                }
                {
                    (this.state.contentOption === 4 && this.state.carouselItemsActive) &&
                    <Fragment>
                        <LargeModal
                            title="MEDIA DO CASOS DE SUCESSO"
                            onClose={() => this.setState({message_show_confirmation: false})}
                            onClickYes={() => {
                                this.setState({
                                    message_show_confirmation: false,
                                });
                            }}
                            show={this.state.message_show_confirmation}
                            showButtonYes={false}
                        >
                            <CustomCarousel items={this.state.carouselItems || []} />
                        </LargeModal>
                    </Fragment>
                }
                {
                    (this.state.contentOption === 4 && !this.state.carouselItemsActive) &&
                    <Fragment>
                        <MessageConfirmation
                            message_type="information"
                            message={this.state.statusApprovalDiscount !== null ? `` : `Usar esta opção somente para abatimentos de motivos pré-aprovados`}
                            onClose={() => this.setState({message_show_confirmation: false})}
                            onClickYes={async() => {
                                if(this.state.discount === "" || this.state.discount === "0,00")
                                {
                                    return this.message("warning", "Digite o valor do abatimento para prosseguir");
                                }
                        
                                const discount = formatedMoneyBRAToFloat(this.state.discount);
                                const amountSeviceWithDiscount = formatedMoneyBRAToFloat(this.state.amountService) - discount;

                                if (amountSeviceWithDiscount < parseFloat(this.state.amountMinService))
                                {
                                    return this.message("warning", "O valor máximo para o abatimento foi excedido. Corrija o valor para prosseguir.");
                                }
                        
                                if(this.state.choosenDiscountReason === null || this.state.choosenDiscountReason === undefined)
                                {
                                    return this.message("warning", "Selecione um motivo de desconto para prosseguir");
                                }
                        
                                if(this.state.descriptionDiscount === "" || this.state.descriptionDiscount === undefined)
                                {
                                    return this.message("warning", "Adicione uma descrição sobre o abatimento para prosseguir");
                                }
                        
                                if(formatedMoneyBRAToFloat(this.state.discount) > formatedMoneyBRAToFloat(this.state.amountService))
                                {
                                    return this.message("warning", "O valor de abatimento não pode ser maior que o valor do serviço");
                                }
                                this.setState({ newDiscount: true });
                                await this.onActionFormAnalysis();
                                if (this.state.enableEditDiscount)
                                {
                                    await this.updateDataDiscount(this.state.idDiscountEnabledEdit);
                                }
                                else
                                {
                                    await this.sendDiscountToApproval();
                                }
                            }}
                            buttonNegativeName="cancelar"
                            buttonConfirmationName="avançar"
                            onClickNo={() => {
                                this.setState({
                                    message_show_confirmation: false,
                                });
                            }}
                            show={this.state.message_show_confirmation}
                        >
                            <div className="mb-3">
                                <InputGeneral
                                    label="Valor do abatimento"
                                    disabled={(this.state.statusApprovalDiscount !== null && this.state.statusApprovalDiscount !== 4) ? true : false}
                                    mandatory={true}
                                    value={this.state.discount}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        value = value.replace(/\D/g, "");
                                        if (value === "") value = "0";
                                        value = parseInt(value, 10).toString().padStart(3, "0");
                                        const formattedValue = formatedMoneyBRA(value);
                                        this.setState({ discount: formattedValue });
                                    }}
                                    maxLength={12}
                                />
                            </div>
                            <div className="mb-3">
                                {
                                    (this.state.statusApprovalDiscount !== null && this.state.statusApprovalDiscount !== 4 && this.state.choosenDiscountReason !== null) ?
                                    <InputGeneral
                                        label="Motivos pré-aprovados"
                                        value={this.state.choosenDiscountReason.name}
                                        disabled={true}
                                        mandatory={true}
                                    />
                                    :
                                    <Select
                                        classaditional="modernSelectFieldGeneral" 
                                        label="Motivos pré-aprovados"
                                        mandatory={true}
                                        default={this.state.choosenDiscountReason}
                                        options={this.state.optionsDiscountReason} 
                                        onChange={async (choosenDiscountReason) => 
                                        {
                                            await this.setState({ choosenDiscountReason });
                                        }}
                                    />
                                }
                            </div>
                            <div className="mb-3">
                                <InputArea
                                    label="Descrição"
                                    placeholder="...Descreva o caso"
                                    style={{ height: 100 }}
                                    mandatory={true}
                                    disabled={(this.state.statusApprovalDiscount !== null && this.state.statusApprovalDiscount !== 4) ? true : false}
                                    value={this.state.descriptionDiscount}
                                    onChange={(e) => this.setState({descriptionDiscount: e.target.value})}
                                    rows="25" 
                                    cols="50"
                                />
                            </div>
                        </MessageConfirmation>
                    </Fragment>
                }
                <div 
                    className="bodyActivityForm" 
                    style={{padding: `${this.props.inModal ? "0px" : ""}`}}
                >
                    {
                        (this.state.contentOption === 1) &&
                        <Fragment>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm">
                                    <InputGeneral
                                        classaditional="nameActivityForm"
                                        placeholder={this.state.menus[this.state.menusActive].name}
                                        value={this.state.task}
                                        onChange={(e) => this.setState({task: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-6 columnActivityForm">
                                    <Menus
                                        classaditional="menusActivityForm"
                                        menus={this.state.menus}
                                        active={this.state.menusActive}
                                        onChange={async (option) => {
                                            if (option === 0)
                                            {
                                                await this.setState({
                                                    task: "INFORMAÇÃO",
                                                    isHistoric: true
                                                });
                                            }
                                            else
                                            {
                                                await this.setState({
                                                    task: "",
                                                    isHistoric: false
                                                });
                                            }
                                            const type = this.state.menus[option].value;
                                            await this.setState({menusActive: option, type});
                                            await this.changeDateMinAndDateMax();
                                        }}
                                    />
                                </div>
                                {
                                    this.props.permissions.toInsert &&
                                    <Fragment>
                                        <div className="col-6 text-right">
                                            <Button
                                                classaditional="buttonShortcutActivityForm"
                                                name="Cliente na Unidade"
                                                icon="far fa-street-view"
                                                onClick={async() => {
                                                    this.setState({
                                                        isDisabledButton: true,
                                                        show_modal_on_action_form: true
                                                    });

                                                    const resultActivities = await activityController.getByBusiness(this.props.user.PER_ID, this.props.business.id);
                                                    const activities = resultActivities.data.data;
                                                    const activityToUpdate = activities.find(a => a.ACT_TYPE === VISIT_TYPE_ACTIVITY);
                                                    if (activityToUpdate)
                                                    {
                                                        await activityController.update(activityToUpdate.ACT_ID, {
                                                            ACT_IS_SHOWEDUP: 1
                                                        });
                                                    }

                                                    await this.setState({
                                                        task: "INFORMAÇÃO",
                                                        type: "HISTORIC",
                                                        annotations: "● CLIENTE CHEGOU NA UNIDADE PARA ATENDIMENTO."
                                                    });

                                                    await this.onActionForm();

                                                    this.setState({
                                                        isDisabledButton: false,
                                                        show_modal_on_action_form: false
                                                    });
                                                }}
                                                type="button"
                                            />
                                            <Button
                                                classaditional="buttonShortcutActivityForm"
                                                name="Cliente em Atendimento"
                                                icon="far fa-user-chart"
                                                onClick={async() => {
                                                    let owner = this.props.business.owner;
                                                    if (!owner) 
                                                    {
                                                        return this.message("error", "O Cliente não pode ser atendido sem um proprietário, por favor marque o especialista da vez!");
                                                    }
                                                    this.setState({ isDisabledButton: true });
                                                    await this.setState({
                                                        task: "INFORMAÇÃO",
                                                        type: "HISTORIC",
                                                        annotations: "● CLIENTE ENTROU PARA ATENDIMENTO."
                                                    });
                                                    await this.onActionForm();
                                                    this.setState({ isDisabledButton: false });
                                                }}
                                                type="button"
                                            />
                                        </div>
                                    </Fragment>
                                    }
                            </div>
                            { !this.state.isHistoric &&
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 pr-2 columnActivityForm">
                                    <InputGeneral
                                        type="date" 
                                        onChange={(e) => this.setState({dateStart: e.target.value})}
                                        value={this.state.dateStart} 
                                        min={this.state.dateMin} 
                                        max={this.state.dateMax}
                                    />
                                </div>
                                <div className="col-2 pl-2 pr-2 columnActivityForm">
                                    <InputGeneral
                                        placeholder=""
                                        value={this.state.timeStart}
                                        onClick={() => this.setState({showIntervalsStart: !this.state.showIntervalsStart})}
                                        onChange={(e) => this.setState({timeStart: validTime(formatedTime(e.target.value))})}
                                        maxLength={5}
                                    />
                                    {
                                        this.state.showIntervalsStart &&
                                        <DropDown classaditional="dropdownTimeActivityForm">
                                            {
                                                this.state.intervalsTime.map((t, i) => {
                                                    return (
                                                        <div
                                                            key={`${i}`} 
                                                            className={`timeActivityForm ${(t === this.state.timeStart) ? "active" : ""}`}
                                                            onClick={() => this.setState({timeStart: t, showIntervalsStart: false})}
                                                        >{formatedTime(t)}</div>
                                                    )
                                                })
                                            }
                                        </DropDown>
                                    }
                                    {
                                        this.state.timeStart !== "" &&
                                        <ButtonIcon
                                            icon="fal fa-times"
                                            classaditional="buttonClearTimeActivityForm"
                                            onClick={() => this.setState({timeStart: ""})}
                                        />
                                    }
                                </div>
                            </div>
                            }
                        </Fragment>
                    }
                    {
                        (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                        <div className="row no-gutters lineActivityForm">
                            <div className="col-12 columnActivityForm">
                                <InputArea
                                    classaditional="annotationsActivityForm"
                                    placeholder="...suas anotações"
                                    value={this.state.annotations}
                                    onChange={(e) => this.setState({annotations: e.target.value})}
                                    rows="25" 
                                    cols="50"
                                />
                            </div>
                        </div>
                    }
                    {
                        (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                        <Fragment>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-12 columnActivityForm file">
                                    <ButtonIcon
                                        classaditional="buttonFileActivityForm"
                                        icon="fas fa-paperclip"
                                        onClick={this.file}
                                    />
                                    {
                                        this.state.file !== "" &&
                                        <span className="nameFileActivityForm">{this.state.file}</span>
                                    }
                                    <InputGeneral
                                        classaditional="d-none"
                                        id="fileActivityForm"
                                        value=""
                                        onChange={(e) => {}}
                                        type="file"
                                        hidden 
                                        accept=".jpg,.png,.jpeg,.pdf"
                                    />
                                </div>
                            </div>
                        </Fragment>
                    }
                    {
                        (this.state.contentOption === 2 || this.state.contentOption === 3) &&
                        <div className="row no-gutters lineActivityForm">
                            <div className="col-12 columnActivityForm d-flex justify-content-center" >
                                <span>nothing...</span>
                            </div>
                        </div>
                    }
                    {
                        (this.state.contentOption === 4) &&
                        <Fragment>
                            <span className="labelSuccessServiceActivityForm">Informações Iniciais:</span>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <Select
                                        label="Instituição Financeira"
                                        default={this.state.choosenFinancialInstitution}
                                        withFieldSearch
                                        options={this.state.optionsFinancialInstitution} 
                                        onChange={(choosenFinancialInstitution) => this.setState({choosenFinancialInstitution})}
                                        mandatory="true"
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Valor da Parcela (R$)"
                                        value={this.state.amountInstallment}
                                        onChange={(e) => this.setState({amountInstallment: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12} 
                                        mandatory="true"
                                    />
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Quantidade de Parcelas"
                                        value={this.state.totalInstallments}
                                        onChange={(e) => this.setState({totalInstallments: formatedOnlyNumber(e.target.value)})}
                                        maxLength={4} 
                                        mandatory="true"
                                    />
                                </div>
                            </div>

                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Quantidade de Parcelas Pagas"
                                        value={this.state.totalInstallmentsPaid}
                                        onChange={(e) => this.setState({totalInstallmentsPaid: formatedOnlyNumber(e.target.value)})}
                                        maxLength={4} 
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Valor Financiado (R$)"
                                        value={this.state.amountFinanced}
                                        onChange={(e) => this.setState({amountFinanced: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Taxas Especiais"
                                        value={this.state.feesSpecialFinancing}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            value = value.replace(/\D/g, "");
                                            if (value === "") value = "0";
                                            value = parseInt(value, 10).toString().padStart(3, "0");
                                            const formattedValue = formatedMoneyBRA(value);
                                            this.setState({ feesSpecialFinancing: formattedValue });
                                        }}
                                        maxLength={12}
                                    />
                                </div>
                            </div>
                            
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Custo do Financiamento (R$)"
                                        disabled
                                        value={this.state.custeFinancing}
                                        onChange={(e) => this.setState({custeFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Juros do Financiamento (R$)"
                                        disabled
                                        value={this.state.feesFinancing}
                                        onChange={(e) => this.setState({feesFinancing: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor já Pago (R$)"
                                        disabled
                                        value={this.state.valuePayed}
                                        onChange={(e) => this.setState({valuePayed: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Parcelas em Aberto"
                                        value={this.state.totalInstallmentsNotPaid}
                                        disabled
                                        onChange={(e) => this.setState({totalInstallmentsNotPaid: formatedOnlyNumber(e.target.value)})}
                                        maxLength={14}
                                    />
                                </div>
                                <div className="col-4 columnActivityForm pl-2 pr-2">
                                    <InputGeneral
                                        label="Valor da Dívida"
                                        disabled
                                        value={this.state.valueDebit}
                                        onChange={(e) => this.setState({valueDebit: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                        
                                    />
                                </div>
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Valor para Quitar (R$)"
                                        disabled
                                        value={this.state.amountToHit}
                                        onChange={(e) => this.setState({amountToHit: formatedMoneyBRA(e.target.value)})}
                                        maxLength={12}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters lineActivityForm">
                                <div className="col-4 columnActivityForm">
                                    <InputGeneral
                                        label="Tipo de Serviço"
                                        classaditional="modernFieldInputGeneral"
                                        disabled
                                        value={this.props.businessService.name}
                                        maxLength={12}
                                    />
                                </div>
                                <div className={`col-4 columnActivityForm d-flex align-items-end ${(this.state.statusApprovalDiscount !== null && this.state.statusApprovalDiscount !== 3) ? "" : "pl-2 pr-2"}`}>
                                    {
                                        (this.state.serviceComplete || this.state.hasInstallments || this.state.haveAdiscountRequestInProgressOrDiscountApproved) || (
                                            <Button
                                                classaditional="buttonAnalysisActivityForm"
                                                name={this.state.isDisabledButton ? "aguarde...": "analisar"}
                                                onClick={async () => {
                                                    this.setState({ isDisabledButton: true, newAnalysis: true });
                                                    await this.onActionFormAnalysis();
                                                    this.setState({ isDisabledButton: false, newAnalysis: false });
                                                }}
                                                disabled={this.state.serviceComplete === true ? true : this.state.isDisabledButton}
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <span className="mandatoryInformationActivityForm">(*) campos com preenchimento obrigatório.</span>
                            
                            {
                                (this.state.idAnalysis !== null) &&
                                <Fragment>
                                    <br />
                                    <hr />
                                    {
                                        (this.state.enableSuccessStories === true && this.props.idService !== null) &&
                                        <Fragment>
                                            <div className="row no-gutters lineActivityForm">
                                                <div className="col-12 columnActivityForm mb-3">
                                                    <span className="labelSuccessServiceActivityForm">Casos de sucesso nesta mesma financeira:</span>
                                                </div>
                                                <div className="col-12 columnActivityForm">
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Cliente</th>
                                                                {/* <th>Banco</th> */}
                                                                <th>Divida era</th>
                                                                <th>Quitou por</th>
                                                                <th>Desconto de</th>
                                                                <th>Midia</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.successStories.map((client, index) => (
                                                                <tr key={index}>
                                                                    <td>{anonymizeName(client.name)}</td>
                                                                    {/* <td>{client.bank}</td> */}
                                                                    <td>{client.debtValue}</td>
                                                                    <td>{client.settledValue}</td>
                                                                    <td>{client.discount}</td>
                                                                    <td>
                                                                        <Button
                                                                            className="buttonUrlLinkActivityForm"
                                                                            icon="fa fa-external-link"
                                                                            onClick={() => {
                                                                                let carouselItems = [];
                                                                                client.media.forEach(item => {
                                                                                    carouselItems.push({
                                                                                        type: 'iframe',
                                                                                        src: `https://drive.google.com/file/d/${item.file}/preview`,
                                                                                        alt: item.title,
                                                                                        caption: { title: item.title, text: '' },
                                                                                    })
                                                                                });
                                                                                
                                                                                this.setState({ carouselItems: carouselItems, carouselItemsActive: true, message_show_confirmation: true});
                                                                            }}
                                                                        ></Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                    }
                                    {
                                        (this.state.discounts.length > 0) &&
                                        <Fragment>
                                            <div className="row no-gutters lineActivityForm mb-3">
                                                <div className="col-12 columnActivityForm mb-3">
                                                    <span className="labelSuccessServiceActivityForm">Solicitação de abatimento:</span>
                                                </div>
                                                <div className="col-12 columnActivityForm">
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                {/* <th>ID</th> */}
                                                                <th>Motivo do abatimento</th>
                                                                <th>Valor s/ abatimento</th>
                                                                <th>Valor do abatimento</th>
                                                                <th>Valor c/ abatimento</th>
                                                                {/* <th>Data de Criação</th> */}
                                                                <th>Descrição</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                            {
                                                                this.state.discounts.map((discount, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ verticalAlign: "middle" }}>{this.state.optionsDiscountReason.find((e) => e.value === discount.BAD_ID_DISCOUNT_REASON)?.name || "Não especificado"}</td>
                                                                        <td style={{ verticalAlign: "middle" }}>{"R$ " + formatedMoneyBRA(this.state.amountServiceDiscount)}</td>
                                                                        <td style={{ verticalAlign: "middle" }}>{"R$ " + formatedMoneyBRA(discount.BAD_AMOUNT_DISCOUNT)}</td>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            {(() => {
                                                                                const calculatedDiscount = formatedMoneyBRAToFloat(this.state.amountServiceDiscount) - parseFloat(discount.BAD_AMOUNT_DISCOUNT);
                                                                                return "R$ " + formatedMoneyBRA(formatedMoneyBRAFromFloat(calculatedDiscount));
                                                                            })()}
                                                                        </td>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            <TruncatedText
                                                                                description={discount.BAD_DESCRIPTION}
                                                                                onClick={(desc) => this.message("information", desc)}
                                                                            />
                                                                        </td>
                                                                        <td style={{ verticalAlign: "middle" }}>
                                                                            {
                                                                                this.state.permissionDiscountApproved === 1 && discount.BAD_STATUS === 1 ?
                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                    <Button
                                                                                        style={{height: 32, fontWeight: 400, fontSize: 14, backgroundColor: "#DC3545"}}
                                                                                        name={"negar"}
                                                                                        onClick={() => {
                                                                                            this.updateStatusDiscount(discount.BAD_ID, 3);
                                                                                        }}
                                                                                    >
                                                                                    </Button>
                                                                                    <Button
                                                                                        style={{height: 32, fontWeight: 400, fontSize: 14,     marginLeft: 16}}
                                                                                        name={"aprovar"}
                                                                                        onClick={() => {
                                                                                            this.updateStatusDiscount(discount.BAD_ID, 2);
                                                                                        }}
                                                                                    >
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                <Button
                                                                                    style={{height: 32, fontWeight: 400, fontSize: 14, backgroundColor: (this.state.permissionDiscountApproved === 1 && discount.BAD_STATUS === 1) ? "" : this.state.statusApprovalDiscountIcon[discount.BAD_STATUS].color}}
                                                                                    name={(this.state.permissionDiscountApproved === 1 && discount.BAD_STATUS === 1) ? "aprovar" : this.state.statusApprovalDiscountIcon[discount.BAD_STATUS].name}
                                                                                    onClick={() => {
                                                                                        let status = 0;

                                                                                        switch (discount.BAD_STATUS)
                                                                                        {
                                                                                            case 1:
                                                                                                status = 2;
                                                                                                break;

                                                                                            case 4:
                                                                                                status = 1;
                                                                                                break;
                                                                                        
                                                                                            default:
                                                                                                break;
                                                                                        }

                                                                                        if(status === 2 && this.state.permissionDiscountApproved !== 1)
                                                                                        {
                                                                                            return;
                                                                                        }

                                                                                        this.updateStatusDiscount(discount.BAD_ID, status);
                                                                                    }}
                                                                                >
                                                                                </Button>
                                                                            }
                                                                        </td>
                                                                        {
                                                                            (discount.BAD_STATUS !== 2 && discount.BAD_STATUS !== 3) &&
                                                                            <td>
                                                                                <Button
                                                                                    classaditional="buttonDeleteDiscountActivityForm"
                                                                                    icon="fa fa-trash"
                                                                                    onClick={() => {
                                                                                        this.deleteDiscount(discount.BAD_ID);
                                                                                    }}
                                                                                >
                                                                                </Button>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <hr />
                                        </Fragment>
                                    }
                                    
                                    <div className="row no-gutters lineActivityForm mb-3">
                                        <div className="col-6 columnActivityForm mb-3">
                                            <span className="labelSuccessServiceActivityForm">Pagamento à Vista:</span>
                                        </div>
                                        <div className="col-6 columnActivityForm mb-3 d-flex justify-content-end">
                                            <Button
                                                classaditional="buttonRefreshAnalysisActivityForm"
                                                icon={this.state.disableButtonRefreshAnalysis ? "fas fa-hourglass-start" : "fas fa-sync"}
                                                disabled={this.state.disableButtonRefreshAnalysis}
                                                onClick={async () => {
                                                    this.setState({disableButtonRefreshAnalysis: true})
                                                    await this.loaderFunctions();
                                                    this.setState({disableButtonRefreshAnalysis: false})
                                                }}
                                            />
                                        </div>
                                        <div className="col-3 columnActivityForm">
                                            <InputGeneral 
                                                classaditional="valueAuthorizationSuccessAcitivityForm"
                                                label="Valor de Serviço (R$)"
                                                value={this.state.amountService}
                                                disabled
                                                maxLength={12}
                                                
                                            />
                                        </div>
                                        <div
                                            className="col-4 columnActivityForm pl-2 pr-2 d-flex align-items-center"
                                            >
                                            <span style={{textAlign: 'center', fontWeight: 400, fontSize: '14px', marginTop: '25px', color: "#32363a"}}>(AFERIÇÃO DE TAXAS E CÁLCULO PERICIAL)</span>
                                        </div>
                                        <div className="col-2 d-flex align-items-end"></div>
                                        <div className="col-3 d-flex align-items-end">
                                            {
                                                (this.props.business.owner.id === this.props.user.PER_ID && !this.state.serviceComplete && !this.state.hasInstallments && !this.state.disableButtonRequestDiscount) && (
                                                    <Button
                                                        style={{
                                                            height: 50,
                                                            fontSize: 18,
                                                            background: "#fff",
                                                            fontWeight: 400,
                                                            border: "1px solid #747678",
                                                            color: "#747678"
                                                        }}
                                                        classaditional="buttonActivityForm positive"
                                                        name={this.state.enableEditDiscount ? "editar abatimento" : "solicitar abatimento"}
                                                        onClick={async () => {
                                                            const responseFinService = await financialServicesController.getFinServiceByIdService(this.props.businessService.id);
                                                            await this.setState({ typeButtonDiscount: 1, amountMinService: responseFinService.data.FIN_MINIMUM_AMOUNT });
                                                            await this.setState({ message_show_confirmation: true });
                                                        }}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="row no-gutters lineActivityForm">
                                        <div className="col-12 columnActivityForm">
                                            <Button 
                                                className="button buttonLinkDetailsBusiness"
                                                icon={(this.state.handleSimulateInstallments === false) ? "fa fa-angle-down buttonIconSpacingActivityForm" : "fa fa-angle-up buttonIconSpacingActivityForm"}
                                                name="Simular"
                                                style={{ width: 'fit-content' }}
                                                classaditional="buttonLinkDetailsBusiness"
                                                onClick={() => {
                                                    this.setState({handleSimulateInstallments: (this.state.handleSimulateInstallments === false) ? true : false})
                                                    if (this.state.choosenInstallment && this.state.choosenInstallment !== null && this.state.choosenInstallment.value !== 0)
                                                    {
                                                        this.simulateInstallments();
                                                    }
                                                }}
                                            ></Button>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            
                            {
                                this.state.handleSimulateInstallments !== false &&
                                <Fragment>
                                    <hr />
                                    <div className="row no-gutters lineActivityForm">
                                        <div className="col-12 columnActivityForm mb-3">
                                            <span className="labelSuccessServiceActivityForm">Condições de pagamento:</span>
                                        </div>
                                        <div className="col-3 columnActivityForm">
                                            <InputGeneral 
                                                classaditional="valueAuthorizationSuccessAcitivityForm"
                                                label="Valor de Serviço (R$)"
                                                value={this.state.amountService}
                                                disabled
                                                maxLength={12}
                                                
                                            />
                                        </div>
                                        <div className="col-3 columnActivityForm pl-2">
                                            <InputGeneral
                                                style={{height: 50, fontSize: 20}}
                                                label="Valor da Entrada (R$)"
                                                value={this.state.amountEntry}
                                                classaditional="activityFormInstallmentInputActivityForm"
                                                disabled={this.state.serviceComplete || this.state.hasInstallments}
                                                onChange={ async (e) => {
                                                    const returnToValueBeforeChange = this.state.amountEntry;
                                                    await this.setState({amountEntry: formatedMoneyBRA(e.target.value)});
                                                    
                                                    if (formatedOnlyNumber(this.state.amountEntry).length < 5) { return;}

                                                    if (formatedMoneyBRAToFloat(this.state.amountEntry) > formatedMoneyBRAToFloat(this.state.amountService))
                                                    {
                                                        await this.setState({amountEntry: returnToValueBeforeChange, noticeMinimunAmountEntry: true});
                                                    }
                                                    else
                                                    {
                                                        await this.setState({noticeMinimunAmountEntry: false});
                                                    }

                                                    if (formatedMoneyBRAToFloat(this.state.amountEntry) >= 100)
                                                    {
                                                        await this.simulateInstallments();
                                                    }
                                                }}
                                                maxLength={12}
                                            />
                                        </div>
                                        <div className="col-3 columnActivityForm pl-2 pr-2">
                                        {
                                            (this.state.serviceComplete || this.state.hasInstallments) ? (
                                                <InputGeneral
                                                    label="Nº de Parcelas"
                                                    value={this.state.choosenInstallment && this.state.choosenInstallment.name}
                                                    classaditional="activityFormInstallmentInputActivityForm"
                                                    style={{ height: 50, fontSize: 20 }}
                                                    disabled
                                                    maxLength={12}
                                                />
                                            ) : (
                                                <Select
                                                    label="Nº de Parcelas"
                                                    default={this.state.choosenInstallment}
                                                    classaditional={"activityFormSelect"}
                                                    onChange={async (choosenInstallment) => {
                                                        localStorage.setItem("installment", JSON.stringify(choosenInstallment));
                                                        await this.setState({ choosenInstallment: choosenInstallment, needClickOnButtonToSimulate: true });
                                                        this.props.transferAnotherInstallmentToAnotherComponent(choosenInstallment.value);

                                                        if (this.state.choosenInstallment && choosenInstallment.value === 0) {
                                                            this.setState({ amountEntry: this.state.amountService, changeInstallment: true });
                                                            await this.getAnalysisFromClass();
                                                            await this.simulateInstallments();
                                                            this.setState({ amountEntry: this.state.amountService, changeInstallment: false });
                                                        }
                                                    }}
                                                    options={this.state.optionsInstallments}
                                                    mandatory="true"
                                                />
                                            )
                                        }
                                        </div>
                                        <div className="col-3 d-flex align-items-end">
                                            {
                                                (this.state.serviceComplete || this.state.hasInstallments) || (
                                                    <Button
                                                        style={{ height: 50, fontSize: 18, fontWeight: 400 }}
                                                        classaditional="buttonActivityForm positive"
                                                        name="simular parcelamento"
                                                        onClick={async () => {
                                                            if (formatedMoneyBRAToFloat(this.state.amountEntry) < 100) {
                                                                this.message("warning", "Valor da entrada deve ser no mínimo R$ 100,00!");
                                                                return;
                                                            }
                                                            await this.simulateInstallments();
                                                            await this.simulateInstallments();
                                                        }}
                                                        disabled={this.state.isDisabledButton}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <hr />

                                    { (formatedMoneyBRAToFloat(this.state.amountEntry) < 100) && <span style={{color: "#dc3545"}}>Valor da entrada deve ser no mínimo R$ 100,00!</span> }
                                    { (this.state.needClickOnButtonToSimulate) && <span style={{color: "#317ae2"}}>Você alterou o número de parcelas. Clique no botão para atualizar a simulação.</span> }
                                    { (!this.state.amountEntry) && <span style={{color: "#dc3545"}}>Adicione uma entrada para gerar as parcelas.</span> }
                                    { (this.state.noticeMinimunAmountEntry === true) && <span style={{color: "#dc3545"}}>Valor da entrada não pode ser maior que o valor do serviço!</span> }

                                    <div 
                                        id="installments" 
                                        className={`${this.state.choosenInstallment && this.state.choosenInstallment.value > 0 && this.state.noticeMinimunAmountEntry === false && this.state.amountEntry && formatedMoneyBRAToFloat(this.state.amountEntry) >= 100 ? 'containerInstallments' : 'd-none'}`}
                                    >
                                        {this.state.installmentElements}
                                    </div>
                                </Fragment>
                            }
                            <br />
                        </Fragment>
                    }

                </div>
                <div className="footerActivityForm">
                    <div
                        className="row no-gutters lineActivityForm"
                        style={{
                            padding: `${this.props.inModal ? "0px" : ""}`,
                            height: `${this.state.contentOption === 4 ? "80px" : ""}`,
                            border: `${this.props.inModal ? "none" : ""}`,
                            backgroundColor: `${this.props.inModal || this.state.contentOption === 4 ? "transparent" : ""}`, 
                            justifyContent: this.state.contentOption === 4 ? "space-between" : "flex-end"
                        }}
                    >
                        {/* {
                            (this.state.contentOption === 1) &&
                            <Check
                                classaditional={`checkTaskActivityForm ${(this.state.finished) ? "active" : ""}`}
                                checked={this.state.finished}
                                name="Marcar como feito"
                                onClick={() => this.setState({finished: !this.state.finished})}
                            />
                        } */}
                        {
                            (this.state.contentOption === 0 || this.state.contentOption === 1) &&
                            <Fragment>
                                <Button
                                    classaditional="buttonActivityForm"
                                    name="Cancelar"
                                    onClick={() => this.message("information", "nothing...")}
                                />
                                {
                                    this.props.permissions.toInsert &&
                                    <Button
                                        classaditional="buttonActivityForm positive"
                                        name="Salvar"
                                        onClick={async () => {
                                            this.setState({ isDisabledButton: true });
                                            await this.onActionForm();
                                            this.setState({ isDisabledButton: false });
                                        }}
                                        disabled={this.state.isDisabledButton}
                                    />
                                }
                            </Fragment>
                        }
                        {
                            (this.state.contentOption === 4 && this.props.idService !== null) &&
                            <div
                                
                                className="col-4 d-flex justify-content-end"
                                style={{ position: "absolute", top: 15, right: 56, width: 383 }}
                            >
                                {
                                    (this.state.haveAdiscountRequestInProgress === false  && (this.props.business.owner.id === this.props.user.PER_ID || this.props.user.PER_ID === 9 || this.props.user.PER_ID === 12) && (this.props.idService === null ? this.props.permissionsServicesToInsert : this.props.permissionsServicesToUpdate)) &&
                                    <Button
                                        name={(this.state.isDisabledButtonCreateService ? "aguarde..." : "editar serviço")}
                                        classaditional={"buttonEditServiceActivityForm"}
                                        disabled={this.state.isDisabledButtonCreateService}
                                        onClick={ async () => {
                                            if (this.state.isDisabledButtonCreateService) { return; }
                                            this.setState({ loading: true, isDisabledButtonCreateService: true });

                                            if (this.state.choosenInstallment && this.state.choosenInstallment.value === 0)
                                            {
                                                this.setState({ amountEntry: this.state.amountService });

                                                await this.getAnalysisFromClass();
                                                await this.simulateInstallments();
                                            }
                                            
                                            await this.props.onClickButtonService();
                                            
                                            setTimeout(() => {
                                                this.setState({ loading: false, isDisabledButtonCreateService: false });
                                            }, 10000);
                                        }}
                                    />
                                }
                            </div>
                        }
                        {
                            (this.state.contentOption === 4 && this.state.idAnalysis !== null ) &&
                            <Fragment>
                                <div className="col-3 d-flex justify-content-end"></div>
                                <div className="col-3 d-flex justify-content-end"></div>
                                <div className="col-3 d-flex justify-content-end"></div>
                                <div className="col-3 d-flex justify-content-end">
                                    {
                                        (this.state.haveAdiscountRequestInProgress === false && this.state.error === false && (this.props.business.owner.id === this.props.user.PER_ID || this.props.user.PER_ID === 9 || this.props.user.PER_ID === 12) && (this.props.idService === null ? this.props.permissionsServicesToInsert : this.props.permissionsServicesToUpdate)) &&
                                        <Button
                                            classaditional={"buttonAddServiceActivityForm"}
                                            name={ (this.props.idService === null) ? (this.state.isDisabledButtonCreateService ? "aguarde..." : "adicionar serviço") : (this.state.isDisabledButtonCreateService ? "aguarde..." : "editar serviço")}
                                            disabled={this.state.isDisabledButtonCreateService}
                                            onClick={async () => {
                                                if (this.state.isDisabledButtonCreateService) { return; }
                                                if(formatedMoneyBRAToFloat(this.state.amountService) < parseFloat(this.state.amountMinService))
                                                {
                                                    return this.message("error", "O Valor de serviço atual excede o valor mínimo de serviço. Corrija para conseguir adicionar um serviço.")
                                                }

                                                this.setState({ loading: true, isDisabledButtonCreateService: true });

                                                if (this.state.choosenInstallment && (this.state.choosenInstallment.value === 0 || this.state.choosenInstallment.value === null))
                                                {
                                                    this.setState({ amountEntry: this.state.amountService });

                                                    await this.getAnalysisFromClass();
                                                    await this.simulateInstallments();
                                                }
                                                
                                                await this.props.onClickButtonService();
                                                
                                                setTimeout(() => {
                                                    this.setState({ loading: false, isDisabledButtonCreateService: false });
                                                }, 10000);
                                            }}
                                        />
                                    }
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const analysisPermissions = state.permissions.analysis;
    const permissions = state.permissions.activities;
    const { user } = state.auth;

    return {
        analysisPermissions,
        permissions,
        user
    }
}

export default withRouter(connect(mapStateToProps)(ActivityForm));