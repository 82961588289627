import React, { useState } from "react"; 
import "./LargeModal.css";
import Modal from 'react-bootstrap/Modal';
import { FaCheck } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import { AiOutlineClose } from 'react-icons/ai';
import { FiAlertTriangle, FiAlertCircle } from 'react-icons/fi';
import Button from "./Button";
import SubtitleAnimation from "../general/SubtitleAnimation"

export default function LargeModal(props) {
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <Modal
            {...props}
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            className="large-modal"
        >
            <div className="header">
                {props?.titleService && (
                    <span className="titleService">
                        DADOS DO SERVIÇO - Cód: <b>{props?.titleService.id ? props?.titleService.id : " *** "}</b> | DATA CADASTRO: <b>{props?.titleService.date}</b> | {props?.titleService.typeService}
                    </span>
                )}
                {props?.title && <span className="title">{props?.title}</span>}
                {props?.message_type === "success" && (
                    <span className="status success">
                        <FaCheck className="iconStatus" /> Sucesso!
                    </span>
                )}
                {props?.message_type === "error" && (
                    <span className="status error">
                        <IoCloseSharp className="iconStatus" /> Erro!
                    </span>
                )}
                {props?.message_type === "warning" && (
                    <span className="status warning">
                        <FiAlertTriangle className="iconStatus" /> Aviso!
                    </span>
                )}
                {props?.message_type === "information" && (
                    <span className="status information">
                        <FiAlertCircle className="iconStatus" /> Informação!
                    </span>
                )}

                <div className="containerInfoAndCloser">
                    {
                        props?.signaturesOpen &&
                        <SubtitleAnimation
                            widthSubtitleAnimation="100%"
                            content="Caso tenha mais serviços deste mesmo cliente feche aqui e cadastre os outros." 
                            color="warning"
                            amountLines={2}
                        />
                    }
                    <button className="close" onClick={() => props.onClose()}>
                        <AiOutlineClose className="iconButton" />
                    </button>
                </div>
            </div>
            <div className="body">
                <div className="row no-gutters w-100 lineBodyLargeModal">
                    <div className="col-12">
                        <p>{props.message}</p>
                        {props.children}
                    </div>
                </div>
                {(props.onClickNo || props.onClickYes) && (
                    <div className="row no-gutters lineBodyLargeModal">
                        <div className="col-4 columnBodyMessageConfirmation">
                            {props.onClickNo && (
                                <Button
                                    classaditional="buttonMessageConfirmation"
                                    name="Não"
                                    onClick={(e) => props.onClickNo(e)}
                                />
                            )}
                        </div>
                        <div className="col-2"></div>
                        <div className="col-2"></div>
                        <div className="col-4 columnBodyMessageConfirmation d-flex justify-content-end">
                            {props.onClickYes && props?.showButtonYes !== false && (
                                <Button
                                    classaditional="buttonMessageConfirmation positive"
                                    name={isDisabled ? "Aguarde..." : "Sim"}
                                    onClick={async (e) => {
                                        setIsDisabled(true);
                                        await props.onClickYes(e);
                                        setIsDisabled(false);
                                    }}
                                    disabled={isDisabled}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}