import React, { useEffect, useState } from "react"; 
import "./MessageAttention.css";
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from 'react-icons/ai';
import { FiAlertTriangle } from 'react-icons/fi';

import Button from "../general/Button.jsx"

export default function MessageAttention(props) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimate((prev) => !prev); // Alterna entre true e false
        }, 1000);

        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, []);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={`messageAttention ${animate ? "animate" : ""}`} // Aplica classe de animação
        >
            <div className="content">
                <div className="header">
                    {
                        props?.messageAttentionType === "warning" &&
                        <span className="status warning">
                            <FiAlertTriangle className="iconStatus" />
                            {props?.title ? props?.title : "Aviso!"}
                        </span>
                    }
                    <button className="close" onClick={() => props.onClose()}>
                        <AiOutlineClose className="iconButton" />
                    </button>
                </div>
                <div className="body">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: props.messageAttentionText
                                .replace(/\n{2,}/g, "\n")
                                .replace(/\n/g, "<br />")
                        }}
                    ></p>
                </div>
                <div className="footer">
                    <Button 
                        classaditional="buttonMessageAttention includeMessageAttention" 
                        name="fechar e incluir"
                        onClick={() => props.onClose()} 
                    />
                    <Button 
                        classaditional="buttonMessageAttention sendMessageAttention" 
                        name="enviar"
                        onClick={() => props.onConfirm()} 
                    />
                </div>
            </div>
        </Modal>
    );
}
