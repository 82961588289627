import api, { apiWhatsApp } from "../../src/configuration";
export default class API {


    activity()
    {
        async function get(userId) 
        {
            const result = await api.get(`/activity?ID_PERSON_OWNER=${userId}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByFilters(filters) 
        {
            const result = await api.post("/activities_by_filters", filters)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByBusiness(userId, businessId) 
        {
            const result = await api.get(`/activity?ID_PERSON_OWNER=${userId}&ACT_ID_BUSINESS=${businessId}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/activity", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/activity/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/activity/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function setArchivedFile(idFileActivity, finalStatus)
        {
            const result = await api.post(`/activity_file_to_archived`, {idFileActivity, finalStatus})
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            update,
            remove,
            getByFilters,
            getByBusiness,
            setArchivedFile,
        }
    }

    brandCompanyUnit()
    {
        async function get() 
        {
            const result = await api.get("/brand_company_unit")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/brand_company_unit", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/brand_company_unit/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/brand_company_unit/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove,
            update
        }
    }

    business()
    {
        async function get(idUser, status)
        {
            const result = await api.get(`/business?ID_PERSON_OWNER=${idUser}${status !== null ? `&BUS_STATUS=${status}` : ""}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByClient(idClient)
        {
            const result = await api.get(`/business_by_client/${idClient}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getTotalBusinessLoss(idUser, period)
        {
            const result = await api.get(`/total_business_loss?USER_ID=${idUser}&PERIOD=${period}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getTotalBusinessRecovered(idUser, period)
        {
            const result = await api.get(`/total_business_recovered?USER_ID=${idUser}&PERIOD=${period}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getTotalBusinessLossByPeriod(idUser, dateStart, dateEnds, whatsSlugServices = null)
        {
            const result = await api.get(`/total_business_loss_by_period?USER_ID=${idUser}&DATE_START=${dateStart}&DATE_ENDS=${dateEnds}&WHATS_SERVICES=${whatsSlugServices}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getTotalBusinessRecoveredByPeriod(idUser, dateStart, dateEnds, whatsSlugServices = null)
        {
            const result = await api.get(`/total_business_recovered_by_period?USER_ID=${idUser}&DATE_START=${dateStart}&DATE_ENDS=${dateEnds}&WHATS_SERVICES=${whatsSlugServices}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByFilters(filters)
        {
            const result = await api.post("/business_by_filters", filters)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getBusinessServicesERP(idUser)
        {
            const result = await api.get(`/business_services_erp?ID_PERSON_OWNER=${idUser}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getBusinessServices()
        {
            const result = await api.get(`/business_services`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getRelationshipTargetUnitAndService(idUser)
        {
            const result = await api.get(`/business_services_relationship/${idUser}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getPendingBusinessFromUnits(idUser)
        {
            const result = await api.get(`/pending_business_from_units?USER_ID=${idUser}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id)
        {
            const result = await api.get(`/business/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data)
        {
            const result = await api.post("/business", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id)
        {
            const result = await api.delete(`/business/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getBusinessByIdService(id)
        {
            const result = await api.get(`/business_by_id_service/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function checkBusinessContractData(clientId, type)
        {
            const result = await api.get(`/business/check_business_contract_data/${clientId}?type=${type}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data)
        {
            const result = await api.put(`/business/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function duplicateFromAtoB(data)
        {
            const result = await api.post(`/business_duplicate_from_a_to_b`, data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            }
        }

        async function getInstallmentsAvailable(idPermissionGroup) 
        {
            const result = await api.get(`/business_analysis_installments_by_group_permission/get_installments_available/${idPermissionGroup}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove,
            update,
            getById,
            getByClient,
            getByFilters,
            duplicateFromAtoB,
            getBusinessServices,
            getTotalBusinessLoss,
            getBusinessServicesERP,
            getBusinessByIdService,
            getInstallmentsAvailable,
            checkBusinessContractData,
            getTotalBusinessRecovered,
            getPendingBusinessFromUnits,
            getTotalBusinessLossByPeriod,
            getTotalBusinessRecoveredByPeriod,
            getRelationshipTargetUnitAndService,
        }
    }

    businessAnalysis()
    {
        async function post(data) 
        {
            const result = await api.post("/business_analysis", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/business_analysis/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/business_analysis/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function saveInstallments(data) 
        {
            const result = await api.post("/business_analysis/save_installments", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function sendDiscountToApproval(data) 
        {
            const result = await api.post("/send_discount_to_approval", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function updateStatusDiscount(data) 
        {
            const result = await api.put("/update_status", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function deleteDiscount(data) 
        {
            const result = await api.delete("/delete_discount/" + data.id)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getDiscountByIdBusinessAnalysis(idAnalysis) 
        {
            const result = await api.get(`/get_discount_by_id_business_analysis/${idAnalysis}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            post,
            remove,
            update,
            deleteDiscount,
            saveInstallments,
            updateStatusDiscount,
            sendDiscountToApproval,
            getDiscountByIdBusinessAnalysis
        }
    }

    businessReasonsForLoss()
    {
        async function get(group = null) 
        {
            const queryGroup = group ? `?BUL_GROUP=${group}` : "";
            const result = await api.get(`/business_reasons_for_loss${queryGroup}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/business_reasons_for_loss", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/business_reasons_for_loss/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/business_reasons_for_loss/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove,
            update
        }
    }

    campaign() 
    {
        async function get(userId, status = null) 
        {
            const result = await api.get(`/campaign?USER_ID=${userId}${status === null ? "" : `&CAM_STATUS=${status}`}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getFromClients(userId) 
        {
            const result = await api.get(`/campaigns_from_clients?USER_ID=${userId}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByFilters(filters) 
        {
            const result = await api.post("/campaign_by_filters", filters)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }
        
        async function post(data) 
        {
            const result = await api.post("/campaign", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/campaign/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            update,
            getByFilters,
            getFromClients
        } 
    }

    client() 
    {
        async function getByOwner(filter = "") 
        {
            const result = await api.get(`/clients_by_owner${filter}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }
        
        async function post(data) 
        {
            const result = await api.post("/client", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/client/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            getByOwner,
            post,
            update
        } 
    }

    commissionReport()
    {
        async function get() 
        {
            const result = await api.get("/commission_report")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByCompanyUnit(companyUnitId) 
        {
            const result = await api.get(`/commission_report?COP_ID_COMPANY_UNIT=${companyUnitId}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByMonth(month) 
        {
            const result = await api.get(`/commission_report?MONTH=${month}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/commission_report", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(idCommissionRule) 
        {
            const result = await api.delete(`/commission_report/${idCommissionRule}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/commission_report/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getByCompanyUnit,
            getByMonth,
            post,
            remove,
            update
        }
    }

    commissionReportProspection() 
    {
        async function get(month, group) 
        {
            const result = await api.get(`/commission_report_prospection?MONTH=${month}&GROUP=${group}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(idCommissionRuleProspection) 
        {
            const result = await api.post(`/commission_report_prospection`, {
                CMP_ID_COMMISSION_RULE_PROSPECTION: idCommissionRuleProspection
            })
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/commission_report_prospection/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            get,
            post,
            remove
        }
    }

    commissionReportIntervals() 
    {
        async function post(idCommissionRuleIntervals) 
        {
            const result = await api.post(`/commission_report_intervals`, {
                COI_ID_COMMISSION_RULE_INTERVALS: idCommissionRuleIntervals
            })
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/commission_report_intervals/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function get() 
        {
            const result = await api.get(`/commission_report_intervals`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/commission_report_intervals/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByMonth(month) 
        {
            const result = await api.get(`/commission_report_intervals?MONTH=${month}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            get,
            post,
            remove,
            update,
            getByMonth,
        }
    }

    commissionRules()
    {
        async function get() 
        {
            const result = await api.get("/commission_rules")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByUserAndUnitCompanyAndPeriod(userId, unitCompany, period) 
        {
            const result = await api.get(`/commission_rules_by_filters?USER_ID=${userId}&COR_MONTH=${period}&COR_ID_COMPANY_UNIT=${unitCompany}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/commission_rules/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/commission_rules", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/commission_rules/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/commission_rules/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getByUserAndUnitCompanyAndPeriod,
            getById,
            post,
            remove,
            update
        }
    }

    commissionRulesDetailsProspection()
    {
        async function post(data) 
        {
            const result = await api.post("/commission_rules_details_prospection", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/commission_rules_details_prospection/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/commission_rules_details_prospection/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            post,
            remove,
            update
        }
    }

    commissionRulesDetailsIntervals()
    {
        async function update(id, data) 
        {
            const result = await api.put(`/commission_rules_details_intervals/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/commission_rules_details_intervals", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/commission_rules_details_intervals/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            post,
            remove,
            update,
        }
    }

    commissionRulesProspection()
    {
        async function get(month) 
        {
            const result = await api.get(`/commission_rules_prospection?CRP_MONTH=${month}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/commission_rules_prospection/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/commission_rules_prospection", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/commission_rules_prospection/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/commission_rules_prospection/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getById,
            post,
            remove,
            update
        }
    }

    commissionRulesIntervals()
    {
        async function get(month, idCompanyUnit) 
        {
            const result = await api.get(`/commission_rules_intervals?CRI_MONTH=${month}&CRI_ID_COMPANY_UNIT=${idCompanyUnit}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/commission_rules_intervals/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/commission_rules_intervals", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        // async function remove(id) 
        // {
        //     const result = await api.delete(`/commission_rules_prospection/${id}`)
        //     .catch((err) => err.response);
            
        //     return {
        //         code: result.status,
        //         data: result.data
        //     };
        // }

        // async function update(id, data) 
        // {
        //     const result = await api.put(`/commission_rules_prospection/${id}`, data)
        //     .catch((err) => err.response);
            
        //     return {
        //         code: result.status,
        //         data: result.data
        //     };
        // }

        return { 
            get,
            post,
            getById,
            // remove,
            // update
        }
    }

    companyUnit() 
    {
        async function get() 
        {
            const result = await api.get("/company_unit")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/company_unit", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/company_unit/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            update
        }

    }

    financialServices()
    {
        async function get() 
        {
            const result = await api.get("/financial_services")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/financial_services", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/financial_services/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getFinServiceByIdService (id)
        {
            const result = await api.get(`/financial_services/get_fin_service_by_id_service/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            }
        }

        async function update(id, data) 
        {
            const result = await api.put(`/financial_services/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove,
            update,
            getFinServiceByIdService
        }
    }

    discountReasons()
    {
        async function get() 
        {
            const result = await api.get("/discount_reasons")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
        }
    }

    logsConnect()
    {
        async function getByFilters(filters) 
        {
            const result = await api.post("/logs_connect_by_filters", filters)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function unlock(id, data) 
        {
            const result = await api.put(`/logs_connect/unlock/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { getByFilters, unlock }
    }

    midiaOrigin() 
    {        
        async function get() 
        {
            const result = await api.get("/midia_origin?MID_STATUS=1")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        }
    }

    monthClosureProspection() 
    {        
        async function post(data) 
        {
            const result = await api.post("/month_closure_prospection", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            post
        }
    }

    permissionsComponentActivity()
    {
        async function get() 
        {
            const result = await api.get("/permission_component_activity")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        }
    }

    permissionsDetails() 
    {
        async function get() 
        {
            const result = await api.get("/permission_details")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        }
    }

    permissionsGroup() 
    {
        async function get() 
        {
            const result = await api.get("/permission_group")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/permission_group/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/permission_group", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/permission_group/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getById,
            post,
            update
        }

    }

    permissionsGroupCollections()
    {
        async function getByPermissionGroup(idPermissionGroup)
        {
            const result = await api.get(`/permission_group_collections?PEB_ID_PERMISSIONS_GROUP=${idPermissionGroup}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/permission_group_collections", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/permission_group_collections/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            getByPermissionGroup,
            post,
            remove
        }
    }

    permissionsLevelGroup() 
    {
        async function getByPermissionGroupAndComponentActivity(idPermissionGroup, idComponentActivity) 
        {
            const result = await api.get(`/permission_level_group?PEL_ID_PERMISSIONS_GROUP=${idPermissionGroup}&PEL_ID_PERMISSIONS_COMPONENT_ACTIVITY=${idComponentActivity}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByPermissionGroupAndReference(idPermissionsGroup, component, reference) 
        {
            const result = await api.get(`/permissions_by_component?PEL_ID_PERMISSIONS_GROUP=${idPermissionsGroup}&PEC_SLUG_COMPONENT=${component}&PEC_SLUG_COMPONENT_REFERENCE=${reference}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/permission_level_group", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/permission_level_group/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            getByPermissionGroupAndComponentActivity,
            getByPermissionGroupAndReference,
            post,
            update 
        }
    }

    personCompanyUnit()
    {
        async function getByUser(id) 
        {
            const result = await api.get(`/person_company_unit?PCU_ID_PERSON=${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/person_company_unit", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/person_company_unit/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            getByUser,
            post,
            remove
        }
    }

    prospectionTeamHierarchy()
    {
        async function get() 
        {
            const result = await api.get("/prospection_team_hierarchy")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { get }
    }

    savePermissions() 
    {
        async function update(id, data) 
        {
            const result = await api.put(`/save_permissions/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            update
        }
    }

    serviceQueuePersons()
    {
        async function get() 
        {
            const result = await api.get("/service_queue_persons")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/service_queue_persons/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/service_queue_persons", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/service_queue_persons/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/service_queue_persons/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function updatePosition(data) 
        {
            const result = await api.post("/update_positions", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getById,
            post,
            remove,
            update,
            updatePosition
        }
    }

    preSaleServiceQueuePersons()
    {
        async function get(idUser) 
        {
            const result = await api.get(`/pre_sale_service_queue_persons/${idUser}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(idUser) 
        {
            const result = await api.post(`/pre_sale_service_queue_persons/${idUser}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function changeFrozen(data) 
        {
            const result = await api.put(`/pre_sale_service_queue_persons/change_frozen`, {...data})
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function deletePosition(data) 
        {
            const result = await api.delete(`/pre_sale_service_queue_persons/delete`, {data})
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function deletePositionAll(data) 
        {
            const result = await api.delete(`/pre_sale_service_queue_persons/delete/all`, {data})
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            get,
            post,
            deletePosition,
            deletePositionAll,
            changeFrozen,
        }
    }

    serviceQueueReasonsForChangeFrozen()
    {
        async function get() 
        {
            const result = await api.get("/service_queue_reasons_for_change_frozen")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getByCompanyUnit(idCompanyUnit) 
        {
            const result = await api.get(`/service_queue_reasons_for_change_frozen?SEC_ID_COMPANY_UNIT=${idCompanyUnit}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/service_queue_reasons_for_change_frozen/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/service_queue_reasons_for_change_frozen", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/service_queue_reasons_for_change_frozen/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/service_queue_reasons_for_change_frozen/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getByCompanyUnit,
            getById,
            post,
            remove,
            update
        }
    }

    stamp() 
    {
        async function get() 
        {
            const result = await api.get("/stamp")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/stamp/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/stamp", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/stamp/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            getById,
            post,
            update
        }
    }

    system()
    {
        async function get() 
        {
            const result = await api.get("/system")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/system", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/system/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            update
        }
    }

    systemUsersForNotifications()
    {
        async function get() 
        {
            const result = await api.get("/system_users_for_notifications")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/system_users_for_notifications", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function remove(id) 
        {
            const result = await api.delete(`/system_users_for_notifications/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            remove
        }
    }

    user() 
    {
        async function forgotPassword(data) 
        {
            const result = await api.post("/user/forgot_password", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function get(userId = null) 
        {
            const result = await api.get(`/user${userId !== null ? `?OWNER_ID=${userId}` : "" }`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getAllUsersByCompanyUnit(idUserLogged, status = null) 
        {
            const result = await api.get(`/users_by_company_unit?USER_ID=${idUserLogged}${status !== null ? `&PER_STATUS=${status}` : ""}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getAllSpecialistByUnitCompany(companyUnitId) 
        {
            const result = await api.get(`/user?PER_ID_COMPANY_UNIT=${companyUnitId}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getManagerOfCompanyUnit(companyUnitId) 
        {
            const result = await api.get(`/manager_of_company_unit?PER_ID_COMPANY_UNIT=${companyUnitId}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getById(id) 
        {
            const result = await api.get(`/user/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function getOwnersForUserOnAccess(id) 
        {
            const result = await api.get(`/owners_for_user_on_access/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function login(data) 
        {
            const result = await api.post("/user/login", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/user", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.put(`/user/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function updatePassword(data)
        {
            const result = await api.post("/user/update_password", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function changeAvatar(data, id)
        {
            const result = await api.post("/user/changeAvatar/:id", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getAvatar(id)
        {
            const result = await api.get(`/user/getAvatar/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function hasAvatar(id)
        {
            const result = await api.get(`/user/hasAvatar/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function isChangedPasswordFromDate(data)
        {
            const result = await api.post("/user/is_changed_password_from_date", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            get,
            post,
            login, 
            update,
            getById,
            getAvatar,
            hasAvatar,
            changeAvatar,
            updatePassword,
            forgotPassword, 
            getManagerOfCompanyUnit,
            getAllUsersByCompanyUnit,
            getOwnersForUserOnAccess,
            isChangedPasswordFromDate,
            getAllSpecialistByUnitCompany, 
        }

    }

    dashboardProspection() 
    {
        async function get(userId, dateStart, dateEnds, timeStart, timeEnds, dateRunning, target, filters, version) 
        {
            const result = await api.post(`/dashboard_prospection/?USER_ID=${userId}&DATE_START=${dateStart}&DATE_ENDS=${dateEnds}&TIME_START=${timeStart}&TIME_ENDS=${timeEnds}&DATE_RUNNING=${dateRunning}&TARGET=${target}&VERSION=${version}`, filters)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        } 
    }

    dashboardMarketing() 
    {
        async function get(userId, dateStart, dateEnds, timeStart, timeEnds, dateRunning, filters)
        {
            const result = await api.post(`/dashboard_marketing/?USER_ID=${userId}&DATE_START=${dateStart}&DATE_ENDS=${dateEnds}&TIME_START=${timeStart}&TIME_ENDS=${timeEnds}&DATE_RUNNING=${dateRunning}`, filters)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        } 
    }

    debug() 
    {
        async function debugging (sqlString) 
        {
            var result = await api.post("/debug/debugging", {sqlString})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            debugging
        } 
    }

    training() 
    {
        async function get (userId) 
        {
            var result = await api.post(`/training/materials`, {USER_ID: userId})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        } 
    }

    workShiftsPerson() 
    {
        async function get () 
        {
            var result = await api.get("/work_shifts_person")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post (data) 
        {
            var result = await api.post("/work_shifts_person", {...data})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            get,
            post
        } 
    }    

    workShifts() 
    {
        async function get (data) 
        {
            var result = await api.get("/work_shifts")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get
        } 
    }

    absenceAttendants() 
    {        
        async function get(dateStartIntervals, dateEndsIntervals) 
        {
            const result = await api.get(`/absence_attendants?DATE_START=${dateStartIntervals}&DATE_ENDS=${dateEndsIntervals}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data) 
        {
            const result = await api.post("/absence_attendants", data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function update(id, data) 
        {
            const result = await api.post(`/absence_attendants/${id}`, data)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        async function deleteAbsence(id) 
        {
            const result = await api.delete(`/absence_attendants/${id}`)
            .catch((err) => err.response);
            
            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            get,
            post,
            update,
            deleteAbsence
        } 
    }

    whatsapp() 
    {
        async function getAllChats(amount, searching, idUserCRM, idUserCRMOnAccess, toViewAll)
        {
            const result = await apiWhatsApp.post("/getAllChats", {amount, searching, idUserCRM, idUserCRMOnAccess, toViewAll})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function send(idContact, message, phone, idUserCRM, nameUserCRM, idDepartament, idCalled, idUserCRMFromCalled, idDepartamentFromUserWithMultipleDepartaments, idMessageReference, file)
        {
            const result = await apiWhatsApp.post("/send", {idContact, message, phone, idUserCRM, nameUserCRM, idDepartament, idCalled, idUserCRMFromCalled, idDepartamentFromUserWithMultipleDepartaments, idMessageReference, file})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function sendImage(formData)
        {
            const result = await apiWhatsApp.post("/sendImage", formData, {})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function sendDocument(formData)
        {
            const result = await apiWhatsApp.post("/sendDocument", formData, {})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function sendAudio(formData)
        {
            const result = await apiWhatsApp.post("/sendAudio", formData, {})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function deleteMessage(id, idDepartament)
        {
            const result = await apiWhatsApp.delete(`/deleteMessage/${id}/${idDepartament}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getAmountScreenshotToAnswerMessage(id)
        {
            const result = await apiWhatsApp.get(`/getAmountScreenshotToAnswerMessage/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function reTryingDownloadFile(id, idDepartament)
        {
            const result = await apiWhatsApp.get(`/reTryingDownloadFile/${id}/${idDepartament}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function setAudioDuration(idMessageDigiSac, duration)
        {
            const result = await apiWhatsApp.post("/setAudioDuration", {idMessageDigiSac, duration})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            }
        }

        async function closeCalled(idUserCRMFromCalled, nameUserCRMFromCalled, idCalled)
        {
            const result = await apiWhatsApp.post("/closeCalled", {idUserCRMFromCalled, nameUserCRMFromCalled, idCalled})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function updateNotReadCalledsAndMessages(idUserCRMFromCalled, idCalled)
        {
            const result = await apiWhatsApp.post("/updateNotReadCalledsAndMessages", {idUserCRMFromCalled, idCalled})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getAllDepartaments()
        {
            const result = await apiWhatsApp.get("/getDepartaments")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function transferCalled(idContact, cellphoneNumberContact, idUserCRMFromCalled, idCalled, idDepartamentDestiny)
        {
            const result = await apiWhatsApp.post("/transferCalled", {idContact, cellphoneNumberContact, idUserCRMFromCalled, idCalled, idDepartamentDestiny})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function checkingUserWithMultipleDepartaments(id)
        {
            const result = await apiWhatsApp.get(`/checkingUserWithMultipleDepartaments/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getWichDepartamentsSlugsByUser(id)
        {
            const result = await apiWhatsApp.get(`/getWichDepartamentsSlugsByUser/${id}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function setDepartamentsByUser(idUserCRM, slugsDepartament, idsCompanyUnits)
        {
            const result = await apiWhatsApp.post(`/setDepartamentsByUser`, {idUserCRM, slugsDepartament, idsCompanyUnits})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getMessagesByChat(idCalled, idDepartament, idUserCRMOnAccess, idContact, toViewAll, amountBeforeCalleds, activeFilterByParamsURL)
        {
            const result = await apiWhatsApp.post(`/getMessagesByChat`, {idCalled, idDepartament, idUserCRMOnAccess, idContact, toViewAll, amountBeforeCalleds, activeFilterByParamsURL})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function sendMessageOutside(fields)
        {
            const result = await apiWhatsApp.post(`/sendOutside`, {...fields})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function getMessagesByClosedCalled(fields)
        {
            const result = await apiWhatsApp.post(`/getMessagesByClosedCalled`, {...fields})
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return {
            send,
            sendAudio,
            sendImage,
            closeCalled,
            getAllChats,
            sendDocument,
            deleteMessage,
            transferCalled,
            setAudioDuration,
            getMessagesByChat,
            sendMessageOutside,
            getAllDepartaments,
            reTryingDownloadFile,
            setDepartamentsByUser,
            getMessagesByClosedCalled,
            getWichDepartamentsSlugsByUser,
            updateNotReadCalledsAndMessages,
            getAmountScreenshotToAnswerMessage,
            checkingUserWithMultipleDepartaments,
        }
    }

    integrations()
    {
        async function get()
        {
            const result = await api.get("/integrations")
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function post(data)
        {
            const result = await api.post("/integrations", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        async function saveFormFromPage(data)
        {
            const result = await api.post("/integrations/save_form_from_page", data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            };
        }

        return { 
            get,
            post,
            saveFormFromPage
        }
    }

    businessServices()
    {
        async function getByIdCompanyUnit (idCompanyUnit)
        {
            const result = await api.get(`/business_services/${idCompanyUnit}`)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            }
        }

        return {
            getByIdCompanyUnit,
        }
    }

    networkAddressWhiteList()
    {
        async function post (data)
        {
            const result = await api.post(`/network_address_whitelist`, data)
            .catch((err) => err.response);

            return {
                code: result.status,
                data: result.data
            }
        }

        return {
            post,
        }
    }

}