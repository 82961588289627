import React, { Component } from "react";
import "./ItemHistoricActivity.css";
import {
    PHONE_TYPE_ACTIVITY,
    VISIT_TYPE_ACTIVITY,
    MAIL_TYPE_ACTIVITY,
    TASK_TYPE_ACTIVITY,
    HISTORIC_TYPE_ACTIVITY,
    TRANSFER_TYPE_ACTIVITY,
    COME_BACK_TYPE_ACTIVITY,
} from "../../core/constants";
import Check from "../general/Check";
import {dateDiff, formatedDateTimeToBrowserAtMidnight, getDateTimeCurrentBrowserAtMidnight} from "../../auxiliary/generalFunctions";
import ButtonIcon from "../general/ButtonIcon";
import Button from "../general/Button";
import ActivityController from "../../controllers/ActivityController";
import { connect } from "react-redux";
import { baseURL } from "../../configuration";

const initialState = {
    // start info task
    task: "",
    type: PHONE_TYPE_ACTIVITY | VISIT_TYPE_ACTIVITY | MAIL_TYPE_ACTIVITY | TASK_TYPE_ACTIVITY | COME_BACK_TYPE_ACTIVITY,
    createdAt: null,
    finished: false,
    dateStart: "",
    timeStart: "00:00",
    dateEnds: "",
    timeEnds: "00:00",
    files: [],
    // ends info task

    // start info business
    owner: "",
    name: "",
    creator: null,
    annotations: "",
    // ends info business

    showDropActionsEditActivity: false,
    color: "",
    canToArchivedFile: false,
    hasPermissionsLoaded: false,
}

const activityController = new ActivityController();

class ItemHistoricActivity extends Component
{
    state = {...initialState}

    componentDidMount = async () =>
    {
        let {task, type, dateStart, timeStart, dateFinished, finished, creator, annotations, dateEnds, timeEnds, files, createdAt} = this.props.activity;
        let date = new Date(createdAt);
        createdAt = `${this.zeroPad(date.getDate(), 2)}/${this.zeroPad((date.getMonth() + 1), 2)}/${date.getFullYear()} às ${this.zeroPad(date.getHours(), 2)}:${this.zeroPad(date.getMinutes(), 2)}`;

        let {name, owner} = this.props.activity.business;
        await this.setState({task, type, dateStart, timeStart, name, owner, finished, dateFinished, creator, annotations, dateEnds, timeEnds, files, createdAt});
        await this.getColorUrgencyOfExecution();
    }

    componentDidUpdate = async (prevProps, prevState) =>
    {
        // console.log("ITEM HISTORIC ACTIVITY prevProps.hasPermissionsLoaded ", prevProps.hasPermissionsLoaded);
        // console.log("ITEM HISTORIC ACTIVITY this.props.hasPermissionsLoaded ", this.props.hasPermissionsLoaded);

        if (this.props.hasPermissionsLoaded && !this.state.hasPermissionsLoaded)
        {
            this.setState({hasPermissionsLoaded: true});

            if (this.props.permissions.toFile) 
            {
                this.setState({canToArchivedFile: true, });
            }
        }
    }

    getIconTypeProgress = () => 
    {
        let type = this.state.type;

        if (this.state.files.length > 0)
        {
            return "fas fa-paperclip";
        }

        if (this.state.annotations.toUpperCase().includes("ALTEROU STATUS DO NEGÓCIO PARA PERDIDO"))
        {
            return "fas fa-do-not-enter";
        }

        if (this.state.annotations.toUpperCase().includes("ALTEROU STATUS DO NEGÓCIO PARA GANHO"))
        {
            return "fas fa-trophy-alt";
        }

        switch (type)
        {
            case PHONE_TYPE_ACTIVITY:
                return "fas fa-phone-alt";

            case VISIT_TYPE_ACTIVITY:
                return "fas fa-user-friends";

            case TASK_TYPE_ACTIVITY:
                return "fas fa-thumbtack";

            case MAIL_TYPE_ACTIVITY:
                return "far fa-paper-plane";

            case HISTORIC_TYPE_ACTIVITY:
                return "far fa-clock";

            case TRANSFER_TYPE_ACTIVITY:
                return "fas fa-exchange-alt";

            case COME_BACK_TYPE_ACTIVITY:
                return "fas fa-undo-alt";

            default:
                return "fas fa-question";

        }
    }

    removeActivity = async () =>
    {
        await activityController.deleteById(this.props.activity.id);
        this.props.onUpdateActivity();
    }

    getColorUrgencyOfExecution = () =>
    {
        let dateActivity = this.props.activity.dateStartNoFormat;
        dateActivity = formatedDateTimeToBrowserAtMidnight(dateActivity);
        let dateNow = getDateTimeCurrentBrowserAtMidnight();
        let diffOne = dateDiff.inDays(dateActivity, dateNow);
        let diffTwo = dateDiff.inDays(dateNow, dateActivity);
        let color = "";
        
        // WARNING: for maintenance.
        // console.log(`dateActivity: ${dateActivity}, diffOne: ${diffOne}, diffTwo: ${diffTwo}`);
        
        if (diffOne >= 0 && diffTwo <= 0)
        {
            color = "error";
        }
        else if (diffOne === -1 && diffTwo === 1)
        {
            color = "warning";
        }
        else if (diffOne === -2 && diffTwo === 2)
        {
            color = "success";
        }

        this.setState({
            color
        });
    }

    zeroPad = (num, places) => String(num).padStart(places, '0')

    getClassName = () =>
    {
        let className = `itemHistoricActivity`;
        if (this.state.files.length > 0)
        {
            className += ` fileActivity`;
        }

        if (this.state.annotations.toUpperCase().includes("ALTEROU STATUS DO NEGÓCIO PARA PERDIDO"))
        {
            className += ` itemHistoricActivityLoss`;
        }

        if (this.state.annotations.toUpperCase().includes("ALTEROU STATUS DO NEGÓCIO PARA GANHO"))
        {
            className += ` itemHistoricActivityGain`;
        }


        return className;
    }

    render ()
    {
        return (
            <div
                className={this.getClassName()}>
                    <div className="row no-gutters lineProgressItemHistoricActivity">
                        <div className="col-1 columnTypeItemHistoricActivity">
                            <i className={`${this.getIconTypeProgress()} iconProgressTypeItemHistoricActivity`}/>
                            <div className="lineProgressTypeItemHistoricActivity"></div>
                        </div>
                        {
                            this.state.files.length > 0 &&
                            <div className="col-11 columnDetailsItemHistoricActivity pl-0 mt-0 pt-0">
                                    { 
                                        this.state.files.map(f => {
                                            let date = new Date(f.ACF_UPLOAD_DATE);
                                            const day = `${this.zeroPad(date.getDate(), 2)}/${this.zeroPad((date.getMonth() + 1), 2)}/${date.getFullYear()}`;
                                            const time = `${this.zeroPad(date.getHours(), 2)}:${this.zeroPad(date.getMinutes(), 2)}`;
                                            
                                            return (
                                                <div key={f.ACF_ID} className="row no-gutters w-100 pb-1 align-items-center pt-3 pb-3 fileActivityColumn">
                                                    <div className="col">
                                                        <span>
                                                            <i className="far fa-file-image fileIcon" />
                                                        </span>
                                                    </div>
                                                    <div className="col-11 text-left">
                                                        <p className="mb-0">
                                                            <a href={`${baseURL}/uploads/activities/${this.props.activity.id}/${f.ACF_FILE_PATH}`} target="_blank" rel="noreferrer">
                                                                { f.ACF_FILE_NAME }
                                                            </a>
                                                        </p>
                                                        <p className="mb-0">
                                                            <span 
                                                                className="dateStartTaskItemHistoricActivity"
                                                            >
                                                                <span>{ `${day} às ${time}` }</span>
                                                                <i className="fas fa-circle iconCreatorSeparator"></i>
                                                                { `${f.PER_NAME.split(" ")[0]} (${f.PEG_NAME})` }
                                                                <i className="fas fa-circle iconCreatorSeparator"></i>
                                                                { `${(f.ACF_FILE_SIZE/1000).toFixed(2)} KB` }
                                                            </span>
                                                        </p>
                                                        {
                                                            this.state.canToArchivedFile &&
                                                            <ButtonIcon
                                                                icon="fas fa-archive"
                                                                classaditional={`buttonArchivedFileItemHistoricActivity`}
                                                                onClick={() => this.props.onToArchivedFile(f.ACF_ID, f.ACF_STATUS)}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        ) 
                                    }
                            </div>
                        }
                        {
                            this.state.files.length === 0 &&
                            <div className="col-11 columnDetailsItemHistoricActivity">
                                <div className="row no-gutters w-100 pb-1">
                                    <div className="col-12 d-flex flex-direction-row">
                                        {
                                            <div className="ckeckFinishedItemHistoricActivity">
                                                <span className="helpCheckFinishedItemHistoricActivity">Marcar como { this.state.finished ? "pendente" : "realizada" }</span>
                                                <Check
                                                    classaditional="checkFinishedItemHistoricActivity"
                                                    onClick={() => {
                                                        const finished = this.state.finished ? 2 : 1;
                                                        this.props.onCheckActivity(this.props.activity.id, this.state.type, finished);
                                                    }}
                                                    checked={this.state.finished}
                                                />
                                            </div>
                                        }
                                        <span>{this.state.task}</span>
                                        {
                                            <ButtonIcon
                                                classaditional="buttonMoreActionsItemHistoricActivity"
                                                icon="fas fa-ellipsis-h"
                                                onClick={() => this.setState({showDropActionsEditActivity: !this.state.showDropActionsEditActivity})}
                                            />
                                        }
                                        {
                                            this.state.showDropActionsEditActivity &&
                                            <div className="modalMoreActionsEditActivity">
                                                {
                                                    // this.props.permissions.toUpdate &&
                                                    // <Button
                                                    //     classaditional="buttonMoreActionsEditActivity"
                                                    //     name="Editar"
                                                    //     onClick={() => alert("nothing...")}
                                                    // />
                                                }
                                                {
                                                    this.props.permissions.toUpdate &&
                                                    <Button
                                                        classaditional="buttonMoreActionsEditActivity"
                                                        name={`Marcar como ${ this.state.finished ? "pendente" : "realizada" }`}
                                                        onClick={() => {
                                                            const finished = this.state.finished ? 2 : 1;
                                                            this.props.onCheckActivity(this.props.activity.id, this.state.type, finished);
                                                        }}
                                                    />
                                                }
                                                {
                                                    this.props.permissions.toDelete &&
                                                    <Button
                                                        classaditional="buttonMoreActionsEditActivity"
                                                        name="Excluir"
                                                        onClick={() => this.removeActivity()}
                                                    />
                                                }
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="row no-gutters w-100">
                                    <div className="col-9 d-flex flex-direction-row">
                                        <span 
                                            className="dateStartTaskItemHistoricActivity"
                                        >
                                            <span 
                                                style={{
                                                    color: `var(--color-${this.state.color})`
                                                }}
                                            >
                                                {this.state.dateStart} às { this.state.timeStart } 
                                            </span>
                                            <i className="fas fa-circle iconCreatorSeparator"></i>
                                            { this.state.creator !== null ? `${this.state.creator.PER_NAME.split(" ")[0]} (${this.state.creator.PEG_NAME})` : "Sem criador" }
                                        </span>
                                        {/* <span className="ownerTaskItemHistoricActivity">{this.state.owner}</span>
                                        <span className="nameTaskItemHistoricActivity">
                                            <i className="fas fa-user iconNameTaskItemHistoricActivity"></i>
                                            {this.state.name}
                                        </span> */}
                                    </div>
                                    <div className="col-3 text-right pr-3">
                                        <small>{`Criada em ${this.state.createdAt}`}</small>
                                    </div>
                                </div>                  
                                {
                                    this.state.annotations.trim() !== "" &&
                                        <div className="row no-gutters activityAnnotations">
                                            <div className="col-12 d-flex flex-direction-row">
                                                <span className="text-left">{ this.state.annotations }</span>
                                            </div>
                                        </div>
                                }
                            </div>
                        }
                    </div>                  

                {/* in finished task */}
                {
                    // this.state.finished &&
                    // <div className="row no-gutters lineFinishedItemHistoricActivity">
                    //     <div className="col-1 columnTypeItemHistoricActivity">
                    //         <i className="iconFinishedTypeItemHistoricActivity"/>
                    //         <div className={`lineFinishedTypeItemHistoricActivity ${this.props.lastActivity ? "disabled" : ""}`}></div>
                    //     </div>
                    //     <div className="col-11 columnDetailsItemHistoricActivity">
                    //         <div className="row no-gutters w-100 pb-2">
                    //             <div className="col-12 d-flex flex-direction-row">
                    //                 <span>{this.state.task}</span>
                    //             </div>
                    //         </div>
                    //         <div className="row no-gutters">
                    //             <div className="col-12 d-flex flex-direction-row">
                    //                 {/* <span className="dateStartTaskItemHistoricActivity">
                    //                     {this.state.dateEnds}
                    //                 </span> */}
                    //                 <span className="dateStartTaskItemHistoricActivity">
                    //                     {formatedDateTimeToBRA(this.state.dateEnds).toString()} às { this.state.timeEnds } 
                    //                     <i className="fas fa-circle iconCreatorSeparator"></i>
                    //                     { this.state.creator?.PER_NAME || "Sem criador" }
                    //                 </span>
                    //                 {/* <span className="ownerTaskItemHistoricActivity">{this.state.owner}</span> */}
                    //                 {/* <span className="nameTaskItemHistoricActivity">
                    //                     <i className="fas fa-user iconNameTaskItemHistoricActivity"></i>
                    //                     {this.state.name}
                    //                 </span> */}
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                }
            </div>
        )
    }
}

function mapStateToProps (state)
{
    const permissions = state.permissions.activities;
    const hasPermissionsLoaded = state.permissions.hasPermissionsLoaded;

    return {
        permissions,
        hasPermissionsLoaded
    }
}

export default connect(mapStateToProps)(ItemHistoricActivity);