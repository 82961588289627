const PERCENT_DISCOUNT_FOR_CASH_MONEY_PAYMENT = 0; // like 10% 
const PERCENT_DISCOUNT_FOR_CASH_CREDIT_CARD_PAYMENT = 0; // like 10% 
const PERCENT_DISCOUNT_FOR_CASH_DEBIT_CARD_PAYMENT = 0; // like 7% 
const PERCENT_ENTRY = 30;

export default class FinancialServices 
{
    #services;

    // constructor ()
    // {
    //     this.loadAllFinancialServices();
    // }

    // Erro's Code FSC001 to FSC199
    // @param Obejct dataInput
    // @return Object response 
    // {
    //      status: 40[X]/50[X],
    //      data: null,
    //      message: "[erro content message]"
    // }
    // OR
    // {
    //      status: 200,
    //      data: {...},
    //      message: ""
    // }
    calculateCostFinancialService (dataInput)
    {
        let response = {
            status: 400,
            data: null,
            message: "",
        }

        try {

            let {   service, 
                    idAnalysis, 
                    amountEntryOld, 
                    newAnalysis, 
                    newDiscount, 
                    discount, 
                    amountFinanced, 
                    totalInstallments, 
                    totalInstallmentsPaid, 
                    amountInstallments, 
                    amountSpecialFees,
                    amountMinService,
                    amountServiceByLongTimeInputValue
                } = dataInput;

            let amountTotalFinancing, 
                amountTotalPaidFinancing, 
                amountFeesFinancing, 
                totalInstallmentsNotPaid, 
                amountNotPaidFinancing,
                amountToHit,
                amountService, 
                amountServiceDiscount, 
                amountServiceByCashMoney,
                amountServiceByCashCreditCard,
                amountServiceByCashDebitCard,
                amountServiceByLongTimeInstallments = 0;

            amountTotalFinancing = (totalInstallments * amountInstallments);
            amountTotalPaidFinancing = (totalInstallmentsPaid * amountInstallments);
            amountFeesFinancing = (amountTotalFinancing - amountFinanced);
            totalInstallmentsNotPaid = (totalInstallments - totalInstallmentsPaid);
            amountNotPaidFinancing = (amountTotalFinancing - amountTotalPaidFinancing);

            if (service === undefined || service === null)
            {
                response.status = 400;
                response.message = "Service not found! (FSC002)";
                return response;
            }

            let {percentRateService, percentFeesService} = service;
            
            amountToHit = (amountNotPaidFinancing * (100 - percentRateService) / 100);
            amountService = (((amountNotPaidFinancing * percentFeesService) / 100));
            amountService = this.roundToVariablePrecision(amountService, -2);

            if (amountService < amountMinService) {
                amountService = amountMinService;
            }

            amountService = amountService + amountSpecialFees;
            amountServiceDiscount = this.roundToVariablePrecision(amountService, -2);
            amountService = amountService - discount;
            amountService = this.roundToVariablePrecision(amountService, -2);

            if (idAnalysis === null || newDiscount === true || newAnalysis === true || amountEntryOld > amountService)
            {
                let amountEntry = amountService * (PERCENT_ENTRY / 100);
                amountServiceByLongTimeInputValue = this.roundToVariablePrecision(amountEntry, -2);
            }

            // service values ​​according to payment method
            // in cash (money, credi card or debit card):
            amountServiceByCashMoney = (amountService - ((amountService) * PERCENT_DISCOUNT_FOR_CASH_MONEY_PAYMENT) / 100);
            amountServiceByCashCreditCard = (amountService - ((amountService) * PERCENT_DISCOUNT_FOR_CASH_CREDIT_CARD_PAYMENT) / 100);
            amountServiceByCashDebitCard = (amountService - ((amountService) * PERCENT_DISCOUNT_FOR_CASH_DEBIT_CARD_PAYMENT) / 100);

            // in installments (long time):
            let amountServiceByLongTimeResidual = (amountService - amountServiceByLongTimeInputValue);
            let installments = new Array(70);
            amountServiceByLongTimeInstallments = installments.fill(1).map((p, i ) => {
                let multiplier = (i + 1);
                let installment = (amountServiceByLongTimeResidual / multiplier);
                let installments = { multiplier, installment };
                return installments;
            });

            let percentEntry = (amountServiceByLongTimeInputValue * 100) / amountService;

            let dataOutput = {            
                amountTotalFinancing,
                amountTotalPaidFinancing,
                amountFeesFinancing,
                totalInstallmentsNotPaid,
                amountNotPaidFinancing,
                amountToHit,
                amountService,
                amountServiceDiscount,
                amountServiceByCashMoney,
                amountServiceByCashCreditCard,
                amountServiceByCashDebitCard,
                amountServiceByLongTimeInstallments,
                amountServiceByLongTimeInputValue,
                percentEntry
            };

            response.status = 200;
            response.data = dataOutput;

        } catch (e) {
            response.status = 500;
            response.message = "Simulation error! (FSC003)";
        }

        return response;
    }
      
    // Erro's Code FSL001 to FSL199
    // NOTE: get all the services from the database.
    loadAllFinancialServices ()
    {
        let response = {
            status: 400,
            data: null,
            message: "",
        }

        try {
            
            this.#services = [
                {
                    name: "Financimento Veículo",
                    slug: "VEHICLE_FINANCING",
                    percentRateService: 51.00,
                    percentFeesService: 20.00,                
                },
                {
                    name: "Empréstimo Bancário",
                    slug: "BANK_LOAN",
                    percentRateService: 60.00,
                    percentFeesService: 21.60,                
                },
                {
                    name: "Cheque Especial",
                    slug: "OVERDRAFT",
                    percentRateService: 60.00,
                    percentFeesService: 21.60,                
                },
                {
                    name: "Cartão de Crédito",
                    slug: "CREDIT_CARD",
                    percentRateService: 60.00,
                    percentFeesService: 21.60,                
                },
                {
                    name: "Empréstimo OMNI",
                    slug: "OMNI_LOAN",
                    percentRateService: 51.00,
                    percentFeesService: 21.60,                
                },
                {
                    name: "Consignado",
                    slug: "CONSENT",
                    percentRateService: 0,
                    percentFeesService: 0,                
                },
            ]

            response.status = 200;

        } catch (e) {
            response.status = 500;
            response.message = "Loading error all services! (FSL001)";
        }
    }

    // @param Float/Interger number e.g. 11954.21
    // @return Flota/interger number (for multiple 100) 
    //          e.g. 12000 (precision like -2); OR 
    //          e.g. 11954 (precision like 0); OR 
    //          e.g. 11954.2 (precision like 1)
    roundToVariablePrecision (number, precision) 
    {
        precision = Math.pow(10, precision)
        return Math.ceil(number * precision) / precision;
    }
}